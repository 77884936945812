import {RootState} from 'app/rootReducer';

import {createSelector} from '@reduxjs/toolkit';

import {WellniteAudiosState} from './wellniteAudiosSlice';

export function getWellniteAudios(state: RootState): WellniteAudiosState {
  return state.wellniteAudios;
}

export const selectWellniteAudiosStatus = createSelector(
  getWellniteAudios,
  state => state.status,
);

export const selectWellniteAudioTracks = createSelector(
  getWellniteAudios,
  state => state.audios,
);
