import React from 'react';

import {SvgIcon} from './SvgIcon';
export const CaretUp: SvgIcon = ({width = 13, height = 11}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 13 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.0166e-07 9.38219L1.05573 10.5L6.5 4.73562L11.9443 10.5L13 9.38219L6.5 2.5L6.0166e-07 9.38219Z"
      fill="black"
      fillOpacity={1}
    />
  </svg>
);
