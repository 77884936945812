import {
  Audio,
  AudiosPaginationType,
  CloneVoiceArgs,
  CloneVoiceResponse,
  ConvertToAudioArgs,
  ConvertToAudioResponse,
  EndPoints,
  GenerateAudioScriptArgs,
  GenerateAudioScriptResponse,
  GenerateAudioSuggestionsArgs,
  GenerateAudioSuggestionsResponse,
  GenerateCoverImageArgs,
  GenerateCoverImageResponse,
  GetJobStatusArgs,
  GetJobStatusResponse,
  HttpMethods,
  RegenerateAudioParagraphArgs,
  RegenerateAudioParagraphResponse,
  RegenerateAudioTrackArgs,
  RegenerateAudioTrackResponse,
  SubmitAudioArgs,
  SubmitAudioResponse,
} from 'interfaces';
import {Response} from 'redaxios';
import {requestHandler} from 'services/api';
import {unwrapAPIError} from 'utils';
import {v4 as uuidv4} from 'uuid';

const cloneVoice = async (
  data: CloneVoiceArgs,
): Promise<
  Response<{
    message: CloneVoiceResponse;
  }>
> => {
  try {
    const formData = new FormData();
    formData.append('audio', data.file, 'recording-' + uuidv4() + '.wav');
    const result = await requestHandler<
      {
        status: number;
        message: CloneVoiceResponse;
      },
      typeof formData
    >({
      method: HttpMethods.POST,
      url: `${EndPoints.CloneVoice}` as unknown as EndPoints,
      data: formData,
    });

    if (result.data.status !== 200) {
      return Promise.reject(result.data.message);
    }

    data.onSuccess?.(result.data.message);

    return result;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const regenerateAudioParagraph = async (
  data: RegenerateAudioParagraphArgs,
): Promise<
  Response<{
    message: RegenerateAudioParagraphResponse;
  }>
> => {
  try {
    const result = await requestHandler<
      {
        status: number;
        message: RegenerateAudioParagraphResponse;
      },
      typeof data
    >({
      method: HttpMethods.PATCH,
      url: `${EndPoints.RegenerateAudioParagraph}` as unknown as EndPoints,
      data,
    });

    if (result.data.status !== 200) {
      return Promise.reject(result.data.message);
    }

    data.onSuccess?.(result.data.message);

    return result;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const regenerateCoverImage = async (
  data: GenerateCoverImageArgs,
): Promise<
  Response<{
    message: GenerateCoverImageResponse;
  }>
> => {
  try {
    const result = await requestHandler<
      {
        status: number;
        message: GenerateCoverImageResponse;
      },
      typeof data
    >({
      method: HttpMethods.POST,
      url: `${EndPoints.RegenerateCoverImage}` as unknown as EndPoints,
      data,
    });

    if (result.data.status !== 200) {
      return Promise.reject(result.data.message);
    }

    data.onSuccess?.(result.data.message);

    return result;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const generateAudioSuggestions = async (
  data: GenerateAudioSuggestionsArgs,
): Promise<
  Response<{
    message: GenerateAudioSuggestionsResponse;
  }>
> => {
  try {
    const result = await requestHandler<
      {
        status: number;
        message: GenerateAudioSuggestionsResponse;
      },
      typeof data
    >({
      method: HttpMethods.POST,
      url: `${EndPoints.GenerateAudioSuggestions}` as unknown as EndPoints,
      data,
    });

    if (result.data.status !== 200) {
      return Promise.reject(result.data.message);
    }

    data.onSuccess?.(result.data.message);

    return result;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const submitAudio = async (
  data: SubmitAudioArgs,
): Promise<
  Response<{
    message: SubmitAudioResponse;
  }>
> => {
  try {
    const result = await requestHandler<
      {
        status: number;
        message: SubmitAudioResponse;
      },
      typeof data
    >({
      method: HttpMethods.PATCH,
      url: `${EndPoints.SubmitAudio}` as unknown as EndPoints,
      data,
    });

    if (result.data.status !== 200) {
      return Promise.reject(result.data.message);
    }

    data.onSuccess?.(result.data.message);

    return result;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const generateAudioScript = async (
  data: GenerateAudioScriptArgs,
): Promise<
  Response<{
    message: GenerateAudioScriptResponse;
  }>
> => {
  try {
    const result = await requestHandler<
      {
        status: number;
        message: GenerateAudioScriptResponse;
      },
      typeof data.params
    >({
      method: HttpMethods.POST,
      url: `${EndPoints.GenerateAudioScript}` as unknown as EndPoints,
      data: data.params,
    });

    if (result.data.status !== 200) {
      return Promise.reject(result.data.message);
    }

    data.onSuccess?.(result.data.message);

    return result;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const getAudios = async ({
  providerId,
  page,
  pageSize,
}: {
  providerId: string;
  page: number;
  pageSize: number;
}): Promise<
  Response<{
    message: {audios: Audio[]; pagination: AudiosPaginationType};
  }>
> => {
  try {
    const result = await requestHandler<
      {
        status: number;
        message: {audios: Audio[]; pagination: AudiosPaginationType};
      },
      void
    >({
      method: HttpMethods.GET,
      url: `${EndPoints.GetAudios}/${providerId}?page=${page}&limit=${pageSize}` as unknown as EndPoints,
    });

    if (result.data.status !== 200) {
      return Promise.reject(result.data.message);
    }

    return result;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const regenerateAudioTrack = async (
  data: RegenerateAudioTrackArgs,
): Promise<
  Response<{
    message: RegenerateAudioTrackResponse;
  }>
> => {
  try {
    const result = await requestHandler<{
      status: number;
      message: RegenerateAudioTrackResponse;
    }>({
      method: HttpMethods.PATCH,
      url: `${EndPoints.RegenerateAudioTrack}/${data.audioId}` as unknown as EndPoints,
    });

    if (result.data.status !== 200) {
      return Promise.reject(result.data.message);
    }

    data.onSuccess?.(result.data.message);

    return result;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const deleteAudio = async (
  audioId: string,
  onSuccess?: () => void,
): Promise<void> => {
  try {
    await requestHandler<void>({
      method: HttpMethods.DELETE,
      url: `${EndPoints.DeleteAudio}${audioId}` as unknown as EndPoints,
    });
    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const generateAudioCoverImage = async (
  data: GenerateCoverImageArgs,
): Promise<
  Response<{
    message: GenerateCoverImageResponse;
  }>
> => {
  try {
    const result = await requestHandler<
      {
        status: number;
        message: GenerateCoverImageResponse;
      },
      typeof data
    >({
      method: HttpMethods.POST,
      url: `${EndPoints.GenerateCoverImage}` as unknown as EndPoints,
      data,
    });

    if (result.data.status !== 200) {
      return Promise.reject(result.data.message);
    }

    data.onSuccess?.(result.data.message);

    return result;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const convertToAudio = async (
  data: ConvertToAudioArgs,
): Promise<
  Response<{
    message: ConvertToAudioResponse;
  }>
> => {
  try {
    const result = await requestHandler<
      {
        status: number;
        message: ConvertToAudioResponse;
      },
      typeof data
    >({
      method: HttpMethods.POST,
      url: `${EndPoints.ConvertToAudio}` as unknown as EndPoints,
      data,
    });

    if (result.data.status !== 200) {
      return Promise.reject(result.data.message);
    }

    data.onSuccess?.(result.data.message);

    return result;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const getJobStatus = async (
  data: GetJobStatusArgs,
): Promise<
  Response<{
    message: GetJobStatusResponse;
  }>
> => {
  try {
    const result = await requestHandler<
      {
        status: number;
        message: GetJobStatusResponse;
      },
      typeof data
    >({
      method: HttpMethods.GET,
      url: `${data.endpoint}/${data.jobId}` as unknown as EndPoints,
    });

    if (result.data.status !== 200) {
      return Promise.reject(result.data.message);
    }

    data.onSuccess?.(result.data.message);

    return result;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

export const WellniteAudiosService = {
  cloneVoice,
  generateAudioScript,
  regenerateAudioParagraph,
  regenerateCoverImage,
  submitAudio,
  generateAudioSuggestions,
  getAudios,
  regenerateAudioTrack,
  deleteAudio,
  generateAudioCoverImage,
  convertToAudio,
  getJobStatus,
};
