import React from 'react';

import {SvgIcon} from './SvgIcon';

export const SuperCoachIcon: SvgIcon = ({
  width = 32,
  height = 32,
  classes = 'text-white',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classes}
  >
    <rect width="32" height="32" rx="9.26316" className="fill-current" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.0525 9.28125C22.4477 9.28125 23.5788 10.4123 23.5788 11.8076V18.4069C23.5788 19.5327 22.6661 20.4454 21.5403 20.4454H21.4793C20.7624 20.4454 20.1813 21.0265 20.1813 21.7433C20.1813 22.5812 19.1933 23.0276 18.5645 22.4739L16.7384 20.8657C16.4309 20.5948 16.0351 20.4454 15.6253 20.4454H10.9472C9.55197 20.4454 8.4209 19.3143 8.4209 17.9191V11.8076C8.4209 10.4123 9.55197 9.28125 10.9472 9.28125H21.0525ZM20.3847 13.3956C20.7136 13.0668 20.7136 12.5336 20.3847 12.2047C20.0558 11.8758 19.5226 11.8758 19.1938 12.2047L15.4584 15.9401L14.1591 14.6408C13.8303 14.3119 13.2971 14.3119 12.9682 14.6408C12.6394 14.9697 12.6394 15.5028 12.9682 15.8317L14.863 17.7264L15.4584 18.3219L16.0539 17.7264L20.3847 13.3956Z"
      fill="url(#paint0_linear_9704_700)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_9704_700"
        x1="15.9998"
        y1="9.28125"
        x2="10.4588"
        y2="24.5893"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="black" stopOpacity="1" />
        <stop offset="1" stopColor="#EAEFFD" stopOpacity="1" />
      </linearGradient>
    </defs>
  </svg>
);

export const CoachWarningIcon: SvgIcon = ({
  width = 29,
  height = 29,
  classes = 'text-white',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 29 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classes}
  >
    <path
      d="M16.6725 2.88066L26.8141 17.203C28.1487 19.0878 26.7758 21.75 24.187 21.75H3.90389C1.31517 21.75 -0.0577999 19.0878 1.27682 17.203L11.4184 2.88066C12.6629 1.12311 15.428 1.12311 16.6725 2.88066Z"
      stroke="black"
      strokeWidth="1.5"
    />
    <path
      d="M13.5207 15.2934L13.1607 8.27344H15.3807L15.0057 15.2934H13.5207ZM14.2707 18.8784C13.9207 18.8784 13.6307 18.7634 13.4007 18.5334C13.1707 18.3034 13.0557 18.0284 13.0557 17.7084C13.0557 17.3884 13.1707 17.1184 13.4007 16.8984C13.6307 16.6684 13.9207 16.5534 14.2707 16.5534C14.6207 16.5534 14.9057 16.6684 15.1257 16.8984C15.3457 17.1184 15.4557 17.3884 15.4557 17.7084C15.4557 18.0284 15.3457 18.3034 15.1257 18.5334C14.9057 18.7634 14.6207 18.8784 14.2707 18.8784Z"
      fill="black"
    />
  </svg>
);

export const CoachPrivacyDataSecurityIcon: SvgIcon = ({
  width = 19,
  height = 22,
  classes = 'text-white',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 19 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classes}
  >
    <path
      d="M0.75 10.5C0.75 8.70507 2.20507 7.25 4 7.25H15C16.7949 7.25 18.25 8.70507 18.25 10.5V17.5C18.25 19.2949 16.7949 20.75 15 20.75H4C2.20507 20.75 0.75 19.2949 0.75 17.5V10.5Z"
      stroke="black"
      strokeWidth="1.5"
    />
    <path
      d="M9.5 11V16"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M15 7.5V4.5C15 2.84315 13.6569 1.5 12 1.5H7C5.34315 1.5 4 2.84315 4 4.5V7.5"
      stroke="black"
      strokeWidth="1.5"
    />
  </svg>
);

export const CoachConsentIcon: SvgIcon = ({
  width = 17,
  height = 21,
  classes = 'text-white',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 17 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classes}
  >
    <path
      d="M1.25 4.5C1.25 2.70507 2.70507 1.25 4.5 1.25H12.5C14.2949 1.25 15.75 2.70507 15.75 4.5V16.5C15.75 18.2949 14.2949 19.75 12.5 19.75H4.5C2.70507 19.75 1.25 18.2949 1.25 16.5V4.5Z"
      stroke="black"
      strokeWidth="1.5"
    />
    <path d="M5 6H12" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M5 9H12" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M5 12H12" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M5 15H9" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);
