import React from 'react';
import {SvgIcon} from 'components/Basic/SvgIcon/SvgIcon';

export const HeartIcon: SvgIcon = ({
  width = 30,
  height = 30,
  strokeColor = '#ED82B3',
  fillColor = 'none',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 30 30"
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.89988 19.7842C2.25763 17.142 2.25764 12.858 4.89989 10.2158C7.54215 7.57352 11.8261 7.57351 14.4683 10.2158L24.5684 20.3158L17.4806 27.4036C16.1106 28.7736 13.8893 28.7736 12.5192 27.4036L4.89988 19.7842Z"
      fill="#FFCB58"
    />
    <path
      // fillRule="evenodd"
      // clipRule="evenodd"
      d="M5.43158 20.3154L15.5316 10.2154C18.1739 7.57313 22.4578 7.57314 25.1001 10.2154C27.7423 12.8576 27.7423 17.1416 25.1001 19.7838L16.9415 27.9425C15.8692 29.0147 14.1308 29.0147 13.0586 27.9425L5.43158 20.3154Z"
      fill={strokeColor}
    />
    <g filter="url(#filter0_d_70_2242)">
      <circle
        cx="9.74666"
        cy="15"
        r="6.81014"
        transform="rotate(-45 9.74666 15)"
        fill="#FF9900"
      />
      <circle
        cx="9.74666"
        cy="15"
        r="5.81014"
        transform="rotate(-45 9.74666 15)"
        stroke="#FFBB25"
        stroke-width="2"
      />
    </g>
    <rect
      x="8.11566"
      y="12.1162"
      width="2.47642"
      height="6.19104"
      fill="#FFCE61"
    />
    <defs>
      <filter
        id="filter0_d_70_2242"
        x="1.43652"
        y="6.68945"
        width="16.6203"
        height="16.6211"
        filterUnits="userSpaceOnUse"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="1.5"
          operator="dilate"
          in="SourceAlpha"
          result="effect1_dropShadow_70_2242"
        />
        <feOffset />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.917647 0 0 0 0 0.937255 0 0 0 0 0.992157 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_70_2242"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_70_2242"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const ActiveTickIcon: SvgIcon = ({
  width = 21,
  height = 20,
  strokeColor = '#747474',
  fillColor = 'none',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 21 20"
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1.25"
      y="0.75"
      width="18.5"
      height="18.5"
      rx="9.25"
      stroke={strokeColor}
      stroke-width="1.5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.7522 5.75312C16.0826 6.09061 16.0826 6.6378 15.7522 6.97529L8.38462 14.5L5.24783 11.2963C4.91739 10.9588 4.91739 10.4116 5.24783 10.0741C5.57828 9.73664 6.11403 9.73664 6.44448 10.0741L8.38462 12.0557L14.5555 5.75312C14.886 5.41563 15.4217 5.41563 15.7522 5.75312Z"
      fill={strokeColor}
    />
  </svg>
);

export const DateRangeIcon: SvgIcon = ({
  width = 21,
  height = 11,
  strokeColor = '#000000',
  fillColor = 'none',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 21 11"
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 1.5C0 1.08579 0.335786 0.75 0.75 0.75H20.25C20.6642 0.75 21 1.08579 21 1.5C21 1.91421 20.6642 2.25 20.25 2.25H0.75C0.335786 2.25 0 1.91421 0 1.5ZM2 5.5C2 5.08579 2.33579 4.75 2.75 4.75H18.25C18.6642 4.75 19 5.08579 19 5.5C19 5.91421 18.6642 6.25 18.25 6.25H2.75C2.33579 6.25 2 5.91421 2 5.5ZM5.75 8.75C5.33579 8.75 5 9.08579 5 9.5C5 9.91421 5.33579 10.25 5.75 10.25H15.25C15.6642 10.25 16 9.91421 16 9.5C16 9.08579 15.6642 8.75 15.25 8.75H5.75Z"
      fill={strokeColor}
    />
  </svg>
);

export const MenuDotsIcon: SvgIcon = ({
  width = 4,
  height = 18,
  strokeColor = '#000000',
  fillColor = 'none',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 4 18"
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="2" cy="2" r="2" fill={strokeColor} />
    <circle cx="2" cy="9" r="2" fill={strokeColor} />
    <circle cx="2" cy="16" r="2" fill={strokeColor} />
  </svg>
);

export const RightArrowIcon: SvgIcon = ({
  width = 15,
  height = 13,
  strokeColor = '#ffffff',
  fillColor = 'none',
  strokeWidth = '2',
  classes = '',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 15 13"
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    className={`stroke-current ${strokeColor} ${classes}`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.86781 0L6.75 1.05573L12.5144 6.5L6.75 11.9443L7.86781 13L14.75 6.5L7.86781 0Z"
      fill={strokeColor}
    />
    <path d="M12.75 6.5H0.25" stroke={strokeColor} strokeWidth={strokeWidth} />
  </svg>
);

export const HeartIconV2: SvgIcon = ({
  width = 60,
  height = 71,
  // strokeColor = '#ED82B3',
  fillColor = 'none',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 60 71"
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.56845 39.8372C4.28393 34.5527 4.28395 25.9849 9.56846 20.7003C14.853 15.4158 23.4209 15.4158 28.7054 20.7003L48.9055 40.9004L32.597 57.2089C31.0349 58.771 28.5022 58.771 26.9401 57.2089L9.56845 39.8372Z"
      fill="#FFCB58"
    />
    <path
      d="M10.6328 40.9001L30.8329 20.7001C36.1174 15.4155 44.6853 15.4156 49.9698 20.7001C55.2543 25.9846 55.2543 34.5525 49.9698 39.837L31.891 57.9157C30.7195 59.0873 28.82 59.0873 27.6484 57.9157L10.6328 40.9001Z"
      fill="#ED82B3"
    />
    <g filter="url(#filter0_d_9066_4109)">
      <circle
        cx="19.262"
        cy="30.2688"
        r="13.6203"
        transform="rotate(-45 19.262 30.2688)"
        fill="#FF9900"
      />
      <circle
        cx="19.262"
        cy="30.2688"
        r="12.6203"
        transform="rotate(-45 19.262 30.2688)"
        stroke="#FFBB25"
        strokeWidth="2"
      />
    </g>
    <rect x="16" y="24.5002" width="4.95283" height="12.3821" fill="#FFCE61" />

    <defs>
      <filter
        id="filter0_d_9066_4109"
        x="4.14258"
        y="15.1486"
        width="30.2402"
        height="30.2405"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="1.5"
          operator="dilate"
          in="SourceAlpha"
          result="effect1_dropShadow_9066_4109"
        />
        <feOffset />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.180392 0 0 0 0 0.384314 0 0 0 0 0.92549 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_9066_4109"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_9066_4109"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
