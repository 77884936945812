export enum HttpMethods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}

export enum EndPoints {
  Login = '/api/login',
  AdminCheckSession = '/api/admin/auth',
  PrescriberCheckSession = '/api/prescriber/auth',
  TherapistCheckSession = '/api/therapist/auth',
  MentalHealthCoachCheckSession = '/api/mentalhealthcoach/auth',
  MemberCheckSession = '/api/patient/auth',
  AdminLogout = '/api/admin/logout',
  PrescriberLogout = '/api/prescriber/logout',
  TherapistLogout = '/api/therapist/logout',
  MentalHealthCoachLogout = '/api/mentalhealthcoach/logout',
  MemberLogout = '/api/patient/logout',
  Register = '/api/patient/register',
  OutsideWellniteRegister = '/api/patient/register/register-scale',
  SaveMarketingChannel = '/api/patient/register/where-from',
  SaveOnboardingData = '/api/patient/register/register-info-start',
  SubmitOnboardingData = '/api/patient/register/register-info-end',
  RequestPasswordToken = '/api/forgot',
  ValidatePasswordToken = '/api/forgot/token',
  ChangePassword = '/api/forgot/reset_password',
  Checkout = '/api/patient/stripe/create-subscription',
  Resubscribe = '/api/patient/stripe/renew-subscription',
  AddCreditCard = '/api/patient/stripe/update-card',
  UpdatePlan = '/api/patient/stripe/update-plan',
  VerifyPassword = '/api/patient/verify/password',
  VerifyPhone = '/api/patient/verify/send-code',
  VerifyPhoneToken = '/api/patient/verify/verify-code',
  UpdateAccount = '/api/patient/profile',
  GetReferralData = '/api/patient/referral/get-code',
  BookAppointment = '/api/patient/acuity/book-appointment',
  BookRecurringAppointment = '/api/patient/acuity/book-appointment/recurring',
  CancelAppointment = '/api/patient/acuity/cancel-appointment',
  GetAppointments = '/api/patient/acuity/appointments/?numRequest=0',
  ProviderAvailability = 'acuity/availability',
  ProviderAvailabilityHours = 'acuity/availability/regular-hours',
  GetProviderAppointments = 'acuity/appointments/?numRequest=0',
  GetNotifications = '/api/notifications',
  UpdateNotification = '/api/notifications/notification',
  SetDefaultCard = '/api/patient/stripe/set-default-card',
  SaveInsuranceImage = '/api/patient/stripe/save-insurance-image',
  AnnualPlanInterest = '/api/patient/stripe/annual-plan-interest',
  PauseSubscription = '/api/patient/stripe/pause-subscription',
  CancelSubscription = '/api/patient/stripe/cancel-subscription',
  CreateAddonSubscription = '/api/patient/stripe/create-addon-subscription',
  CancelAddonSubscription = '/api/patient/stripe/cancel-addon-subscription',
  ShareRequest = '/api/community/share-request',
  SharePost = '/api/community/share-post',
  CascadePostDelete = '/api/community/delete-post',
  GetTwitterImageURL = '/api/community/twitter-share',
  UpdateFeedbackCount = '/api/patient/feedback/update-count',
  RequestOtpCode = '/api/patient/verify/send-code',
  RequestRegistrationPhoneOtpCode = '/api/prescriber/register/send-code',
  UpdatePhoneAndResendOtpCode = '/api/patient/verify/update-phone-resend-code',
  VerifyOtpCode = '/api/patient/verify/verify-code',
  VerifyRegistrationPhoneOtpCode = '/api/prescriber/register/verify-code',
  SaveSocialOnboardingData = '/api/patient/register/update-insurance',
  //minor consent
  UpdateMinorConsent = '/api/patient/register/update-minor-consent',
  UpdateMinorConsentStep = '/api/patient/register/update-consent-step',
  BookOneTimeAppointment = '/api/patient/acuity/book-one-time-appointment',
  OnboardingOneTimePayment = '/api/patient/stripe/onboarding-one-time-charge',
  GetNotes = '/api',
  GetMembers = 'patients/all',
  SearchMembers = 'patients/search',
  GetMember = 'patients/view',
  GetMemberById = 'patients/view-by-id',
  GetMedications = '/api/admin/medication/view-all-medication',
  UpdateProviderProfile = '/profile',
  UpdateProviderProfessionalHeadshot = '/profile/updateProfessionalHeadshot',
  UpdateProviderResume = '/profile/updateProviderResume',
  UploadProviderLicenseCopy = '/profile/uploadProviderLicenseCopy',
  GetWherebyRoomUrl = 'whereby/join',
  UpdatePatientMedication = '/api/prescriber/profile/medications/',
  GetIntakeQTherapyQuestionnaire = '/api/patient/intakeq',
  GetInsuranceNames = '/api/airtable/insurance-names',
  GetAllProviderBills = '/api/billing/get-data',
  SetPlatformFeePercentage = '/profile/set-fee-percentage',
  CalendarSettingsNotification = '/acuity/calendar-settings-notification',
  ReactivateCometUser = '/api/chat/activate',
  UnreadConversations = '/api/chat/unread-conversations',
  DeactivateCometUser = '/api/chat/deactivate',
  ReauthenticateStripeOnboarding = 'stripe/create-stripe-connect-account',
  CompleteStripeOnboarding = 'stripe/check-stripe-connect-onboarding-status',
  CreateStripeConnectAccount = '/stripe/create-stripe-connect-account',
  GetSouthAfricanBankList = 'paystack/get-south-african-banks-list',
  CreatePaystackSubaccount = 'paystack/create-subaccount',
  UpdatePaystackSubaccount = 'paystack/update-subaccount',
  InitiatePaystackTransaction = '/api/patient/paystack/initiate-paystack-transaction',
  VerifyPaystackTransaction = '/api/patient/paystack/verify-paystack-transaction',
  GetPaystackPaymentCardsList = '/api/patient/paystack/list-payment-cards',
  SetPaystackDefaultPaymentCard = '/api/patient/paystack/update-default-payment-card',
  DeleteAPaymentCardOnPaystack = '/api/patient/paystack/delete-payment-card',
  GetDigitalPracticeProviderData = '/api/patient/register/get-digital-practice-provider-data',
  DigitalPracticeOutOfPocketCheckout = '/api/patient/stripe/create-customer-on-stripe-and-link-to-connect-account',
  DigitalPracticeInsuranceCheckout = '/api/patient/stripe/save-credit-card-for-insurance-member',
  BookingCharge = 'acuity/booking-charge',

  GetAccountReleaseNotes = '/api/release-notes/latest',
  UpdateAccountReleaseNotes = '/api/release-notes/account',
  GetWellbitsType = '/collaboration/wellbits',
  GetWellbitWithdrawalHistory = 'collaboration/withdrawal-wellbits-history',
  GetColleagues = '/api/wellbit/get-colleagues',
  SearchColleagues = '/api/wellbit/search-colleagues',
  SetDigitalPracticeCharge = 'appointment/set-digital-practice-charge',
  ChargeDigitalPracticeAppointment = 'appointment/charge-digital-practice-member',
  GetMeetingDetails = 'whereby/meeting',
  GetEmployers = '/api/airtable/insurance-employer-data',

  // collaboration Endpoints
  SendGreetings = '/api/chat/send-greetings',
  WithdrawWellbits = '/collaboration/withdraw-wellbits',
  GetProviderColleagueWellbit = '/api/wellbit/all',

  SendInviteLinkToContacts = '/invites',

  //airtable endpoints
  AirtableProviderRecord = '/api/airtable/provider-data',
  GetAirtableProviderFieldsOptions = '/api/airtable/provider-fields-options',
  GetInsuranceAndColleagues = '/api/airtable/get-insurance-colleagues',

  // acuity group call endpoints
  BookGroupAppointment = '/api/therapist/acuity/book-appointment-for-group-call',
  UpdateGroupCallAppintmentLabel = 'acuity/update-group-call-appointment-label',

  ProviderRegister = '/register',
  ChangeProviderFromFirstTime = '/register/first-time',
  UpdateProviderNpi = '/register/update-npi',
  SkipCAQHForm = '/register/skipCAQHForm',
  ChangeOnboardingVideoStatus = '/register/update-video-status',
  CompleteOnboarding = '/register/complete-onboarding',
  UpdateInsuranceInfo = '/api/patient/register/insurance-eligibility',
  ChangeSocialOnboardingStatus = '/api/patient/register/change-status',
  AvailabilityUpdateNotification = '/acuity/availability-update-notification',
  DirectBookingOptions = 'api/direct-booking/provider-fields-options',
  DirectBookingSearchProviders = 'api/direct-booking/providers',
  DirectBookingSearchProvider = 'api/direct-booking/provider',

  // paypal integration
  GeneratePaypalPartnerReferralLink = '/api/therapist/paypal/generate-partner-referral-link',
  CreatePaypalVaultPaymentToken = '/api/patient/paypal/payment-tokens',
  CreatePaypalVaultSetupToken = '/api/patient/paypal/setup-tokens',
  GetPayPalPaymentCardsList = '/api/patient/paypal/list-payment-cards',
  SetPayPalDefaultPaymentCard = '/api/patient/paypal/update-default-payment-card',
  DeleteAPaymentCardOnPayPal = '/api/patient/paypal/delete-payment-card',
  UpdatePaypalSellerInfo = 'paypal/update-seller-info',
  GetPayPalSellerOnboardingDetails = '/api/therapist/paypal/get-seller-onboarding-details',
  GetRecurringAvailableSlots = 'api/patient/acuity/recurring-booking/available-time',
  SelfAwarenessPatientData = 'api/self-awareness/responses',
  MinorConsentData = '/api/patient/register/sign-consent',
  CreateMoodTrackerEntry = '/api/patient/mood-tracker',
  GetMoodTrackerEntries = '/api/patient/mood-tracker',
  GetMoodTrackerStats = '/api/patient/mood-tracker/stats',
  UpdateProgressAssessment = '/api/patient/progress-assessment',
  GetProgressAssessmentStats = '/api/patient/progress-assessment/stats',
  GetProgressAssessmentAllStats = '/api/patient/progress-assessment/stats/all',
  SelfAwarenessPatientDataPdf = 'responses/view-in-browser',
  SaveSelfAwarenessData = '/api/self-awareness',
  UpdateInternationalProviderCredentialsData = '/register/update-international-provider-credentials',
  UpdateInternationalProviderProfileData = '/register/update-international-provider-details',
  UpdateInternationalProviderProfileTwoData = '/register/update-international-provider-profile-info',
  UploadInternationalProviderIdDocument = '/register/upload-international-provider-id-document',
  SendInternationalProviderAgreedToTerms = '/register/update-technology-platform-agreement',
  ProviderAvailabilityBlocks = 'acuity/availability/blocks',
  SetSpecificDayAvailability = 'acuity/set-availability',
  VerifyHuman = '/register/verifyHuman',
  // availability
  GetAvailableProviders = 'acuity/available-providers',
  GetAvailableSlots = '/api/patient/acuity/available-slots',
  GetProviderDataForAppointment = '/api/patient/acuity/provider-data',

  // Get Currency Rate For International
  CurrencyRateByCode = '/api/currency/by-code',

  CheckUserExists = '/api/patient/check-user-email',
  HandleCountryMismatch = 'handle-country-mismatch',

  SaveIntakeData = '/api/patient/member-intake',
  GetMemberIntakeData = '/api/patient/member-intake/intake-data',
  FetchReceipts = '/appointment/list-receipts',
  FetchReceiptPdf = '/appointment/view-receipt-pdf',
  GetWidgetPatientsDetails = 'patients/widget/patients-details',
  GetWidgetProviderDetails = 'widget/provider-details',
  AppointmentRating = 'appointment/rating',
  DigitalPracticeSessionRate = '/profile/set-digital-practice-session-rate',
  UnsubscribeOnboardingReminder = '/register/reminder/unsubscribe',
  GenerateExpertSummary = '/api/wellnite/expert-summary',
  WellniteContentDraft = '/api/article/draft',
  GenerateWellniteContent = '/api/wellnite/content/generate',
  ReGenerateWellniteContent = '/api/wellnite/content/regenerate',
  SaveWellniteArticle = '/api/article',
  GetWellniteImages = '/api/article/search/photos',
  SendPaymentRequest = '/api/therapist/digital-practice/send-payment-request',
  fetchDPAppointmentDetails = '/api/patient/digital-practice/fetch-appointment-details',
  PayLaterRequest = '/api/patient/digital-practice/pay-later-request',
  GetWellniteArticleBySlug = '/api/article/slug',
  GetWellniteArticlesByProvider = '/api/article/provider',
  CheckIfProviderReachedDailyWellniteContentLimit = '/api/article/validate/provider-can-create',
  TriggerDownloadUnsplashPhotoEvent = '/api/article/trigger-download/photo',
  GenerateWellniteExercise = '/api/wellnite/exercise/generate',
  SaveExercise = '/api/exercise',
  GetWellniteExcercisesByProvider = '/api/exercise/by-provider-patient',
  GetWellniteExcercisesByMember = '/api/exercise/by-patient',
  UpdateExerciseAnswers = '/api/exercise/answers',
  SendInitialExerciseRequest = '/api/exercise/ping-provider',
  GeneratePostSessionQuestionnaire = '/api/wellnite/generate-patient-post-session-questionnaire',
  GenerateImageFromPostSessionQuestionnaire = '/api/wellnite/generate-image-from-patient-post-session-questionnaire',
  GetPaginatedWellniteArtsGallery = '/api/wellnite/get-paginated-magic-art-gallery',
  SearchWellniteArtsGallery = '/api/wellnite/search-magic-art-gallery',
  GetPatientWellniteArts = '/api/wellnite/get-patient-magic-arts',
  GetWellniteArtForYou = '/api/wellnite/get-magic-art-for-you',
  RecordWellniteArtReaction = '/api/wellnite/record-magic-art-reaction',
  RegenerateWellniteArtCaption = '/api/wellnite/regenerate-magic-art-caption',
  GetPatientLikedWellniteArts = '/api/wellnite/get-patient-liked-magic-arts',
  RegenerateQuestion = '/api/wellnite/regenerate-question',
  RegenerateWellniteArtTitle = '/api/wellnite/regenerate-magic-art-title',
  GetWellniteArtById = '/api/wellnite/get-magic-art-by-id',
  GetWellniteArtConditionTags = '/api/wellnite/get-magic-art-condition-tags',
  UpdateWellniteArtInfo = '/api/wellnite/update-magic-art-info',
  SuperCoachCompleteOnboarding = '/api/patient/super-coach/onboarding',
  SendSuperCoachChatMessage = '/api/patient/super-coach/chat',
  GetSuperCoachChatMessages = '/api/patient/super-coach/sessions',
  UpdateProviderCompensationModalStatus = '/profile/update-provider-compensation-modal-status',

  //Wellnite Audios
  CloneVoice = '/api/wellnite/clone-voice',
  GetAudios = 'api/wellnite/get-provider-audios',
  GenerateAudioScript = '/api/wellnite/generate-audio-script',
  ConvertToAudio = '/api/wellnite/convert-to-audio',
  RegenerateAudioParagraph = '/api/wellnite/regenerate-audio-paragraph',
  RegenerateCoverImage = '/api/wellnite/regenerate-cover-image',
  GenerateAudioSuggestions = '/api/wellnite/generate-audio-suggestions',
  SubmitAudio = '/api/wellnite/submit-audio',
  RegenerateAudioTrack = '/api/wellnite/regenerate-audio-track/',
  DeleteAudio = '/api/wellnite/delete-audio/',
  GenerateCoverImage = '/api/wellnite/generate-audio-cover-image',
  SpeechGenerationStatus = '/api/wellnite/speech-generation-status',
  RegenerateParagraphStatus = '/api/wellnite/regenerate-audio-paragraph-status',
  RegenerateTrackStatus = '/api/wellnite/regenerate-audio-track-status',
}
