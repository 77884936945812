export enum StripePlanTypes {
  mindfulness = 'mindfulness',
  uninsured = 'uninsured',
  together = 'together',
  therapy = 'therapy',
  insured = 'insured',

  // new plan
  medicalcare_plus_therapy = 'medicalcare_plus_therapy',
  medicalcare = 'medicalcare',
  new_therapy = 'new_therapy',
}

export enum StripePlanDisplayNames {
  mindfulness = 'Medical Care + Therapy',
  uninsured = 'Medical Care',
  together = 'Social',
  zocdoc = 'Insurance',
  therapy = 'Therapy',
  insured = 'Insured',

  // new plan
  medicalcare_plus_therapy = 'Medical Care + Therapy',
  medicalcare = 'Medical Care',
  new_therapy = 'Therapy',
}

export enum StripePlanNames {
  mindfulness = 'mindfulness',
  insured = 'insured',
  uninsured = 'uninsured',
  together = 'together',
  therapy = 'therapy',
  togetherWeekly = 'together_weekly',
  curandero = 'curandero',
  basicMarketing = 'basic_marketting',
  mindfulnessMarketing = 'mindfulness_marketting',
  mindfulnessBiannually = 'mindfulness_six_months',
  therapyBiannually = 'therapy_six_months',
  insuredBiannually = 'insured_six_months',
  uninsuredBiannually = 'uninsured_six_months',
  mindfulnessAnnually = 'mindfulness_one_year',
  therapyAnnually = 'therapy_one_year',
  insuredAnnually = 'insured_one_year',
  uninsuredAnnually = 'uninsured_one_year',
  uninsuredBiweekly = 'uninsured_biweekly',
  uninsuredBiweeklyReviewed = 'uninsured_biweekly_reviewed',
  basicOld = 'basic_old',
  basic = 'basic',
  privateInsurance = 'private_insurance',
  medicaidMedicare = 'medicaid_medicare',

  // new plan
  medicalCarePlusTherapy = 'medicalcare_plus_therapy',
  medicalCarePlusTherapyBiannually = 'medicalcare_plus_therapy_six_months',
  medicalCarePlusTherapyAnnually = 'medicalcare_plus_therapy_one_year',

  medicalCare = 'medicalcare',
  medicalCareBiweekly = 'medicalcare_biweekly',
  medicalCareBiannually = 'medicalcare_six_months',
  medicalCareAnnually = 'medicalcare_one_year',

  newTherapy = 'new_therapy',
  newTherapyBiannually = 'new_therapy_six_months',
  newTherapyAnnually = 'new_therapy_one_year',

  // for resubscribe
  medicalcare_plus_therapy = 'medicalcare_plus_therapy',
  new_therapy = 'new_therapy',
  medicalcare = 'medicalcare',

  // Addon plans
  superCoach = 'super_coach_monthly',
}

export enum StripePlanDurations {
  weekly = 'weekly',
  biweekly = 'biweekly',
  monthly = 'monthly',
  biannually = 'six_months',
  annually = 'one_year',
}

export const medicalCarePlans = [
  StripePlanNames.uninsuredBiweekly,
  StripePlanNames.uninsuredBiannually,
  StripePlanNames.uninsuredAnnually,
  StripePlanNames.medicalCareBiweekly,
  StripePlanNames.medicalCareBiannually,
  StripePlanNames.medicalCareAnnually,
];
