import React, {FC, ReactNode, Suspense} from 'react';
import {LoadingSpinner} from 'components/Basic';
import {selectMemberLanguageStatus} from 'features/Member';
import {selectProviderLanguageStatus} from 'features/Provider';
import {SliceStatus} from 'interfaces';
import {useSelector} from 'react-redux';
import {StripeService} from 'services';

import {Elements} from '@stripe/react-stripe-js';

export const MainLayout: FC<{children: ReactNode[] | ReactNode}> = ({
  children,
}) => {
  const providerLanguageStatus = useSelector(selectProviderLanguageStatus);
  const memberLanguageStatus = useSelector(selectMemberLanguageStatus);

  const isLanguageLoading =
    providerLanguageStatus === SliceStatus.pending ||
    memberLanguageStatus === SliceStatus.pending;
  return (
    <>
      {isLanguageLoading ? (
        <div className="flex items-center justify-center flex-grow h-screen">
          <LoadingSpinner type="Hearts" width={40} height={40} />
        </div>
      ) : (
        <Elements stripe={StripeService.getStripe()}>
          <div
            data-cy="main-layout"
            className="h-screen h-[calc(100dvh)] w-screen overflow-x-hidden"
          >
            <Suspense>{children}</Suspense>
          </div>
        </Elements>
      )}
    </>
  );
};
