import * as Yup from 'yup';

export const superCoachOnboardingSchema = Yup.object().shape({
  hasAgreedToDisclaimer: Yup.boolean()
    .oneOf([true], 'You must agree to the disclaimer.')
    .required('This field is required.'),
  hasAcknowledgedPrivacyPolicy: Yup.boolean()
    .oneOf([true], 'You must acknowledge the privacy policy.')
    .required('This field is required.'),
  hasConsented: Yup.boolean()
    .oneOf([true], 'You must agree to terms of condition')
    .required('This field is required.'),
});

export const sendSuperCoachChatMessageSchema = Yup.object().shape({
  message: Yup.string().required('This field is required'),
});
