import {AppActions, RootState} from 'app/rootReducer';
import Toast from 'components/Basic/Toast';
import {messagingActions} from 'features/Messaging';
import {notificationActions} from 'features/Notification';
import {userActions} from 'features/User';
import {UserRoles} from 'interfaces';
import {Epic} from 'redux-observable';
import {concat, EMPTY, from, of, ReplaySubject} from 'rxjs';
import {
  catchError,
  delay,
  filter,
  ignoreElements,
  mergeMap,
  skipUntil,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {FirebaseService} from 'services';
import {AuthService} from 'services/api';
import {isMember} from 'utils';

const currentUrl = window?.location?.href || '';

export const loadAppStateEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(userActions.stateLoadingDone.match),
    switchMap(
      ({
        payload: {
          state: {
            user: {current},
          },
          isCacheAvailable,
        },
      }) => {
        if (current) {
          return of(current.role);
        }

        if (!isCacheAvailable) {
          return of(UserRoles.member);
        }

        return EMPTY;
      },
    ),
    switchMap(userRole =>
      from(AuthService.checkSession(userRole)).pipe(
        mergeMap(({data: {message: user}}) => {
          const actions = [];
          if (currentUrl.includes('/practice/register') && user) {
            actions.push(
              userActions.logout(),
              messagingActions.cometChatLogOut(),
            );
          } else {
            actions.push(userActions.setUser(user));
          }
          return of(...actions);
        }),
        catchError(() => of(userActions.resetUser(null))),
      ),
    ),
  );

export const checkSessionEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(userActions.checkSession.match),
    switchMap(({payload: userRole}) =>
      from(AuthService.checkSession(userRole)).pipe(
        mergeMap(({data: {message: user}}) => [
          userActions.setUser(user),
          userActions.checkSessionSuccess(),
        ]),
        catchError(() => of(userActions.logout())),
      ),
    ),
  );

// TODO: Store in server or 3rd party??
const logAppErrorEpic: Epic<AppActions, AppActions, RootState> = action$ =>
  action$.pipe(
    filter(userActions.logData.match),
    tap(console.info),
    ignoreElements(),
  );

const lazyloadFirebaseEpic: Epic<
  AppActions,
  AppActions,
  RootState,
  {firebase: ReplaySubject<firebase.app.App>}
> = (action$, _state$, {firebase}) =>
  action$.pipe(
    filter(userActions.setUser.match),

    take(1),
    tap(({payload: user}) => {
      if (isMember(user)) {
        if (user.paidOrTrialAccess?.message) {
          Toast({type: 'default', message: user.paidOrTrialAccess.message});
        }
      }
    }),
    mergeMap(() => FirebaseService.lazyLoadFirebase()),
    tap(({app}) => {
      firebase.next(app);
    }),
    withLatestFrom(_state$),
    switchMap(() => {
      return concat(
        of(notificationActions.getNotifications({page: 0, limit: 10})),
        of(
          notificationActions.getNotifications({
            page: 0,
            limit: 10,
            notificationType: 'wellbits_created',
          }),
        ),
      );
    }),
    catchError(error => of(userActions.logData(`${error}, lazyloadFirebase`))),
  );

const subsequentLoginsEpic: Epic<
  AppActions,
  AppActions,
  RootState,
  {firebase: ReplaySubject<firebase.app.App>}
> = (action$, state$) =>
  action$.pipe(
    filter(userActions.loginSuccess.match),

    skipUntil(action$.pipe(filter(userActions.setUser.match), take(1))),
    delay(1000),
    withLatestFrom(state$),

    switchMap(() => {
      return concat(
        of(notificationActions.getNotifications({page: 0, limit: 10})),
        of(
          notificationActions.getNotifications({
            page: 0,
            limit: 10,
            notificationType: 'wellbits_created',
          }),
        ),
      );
    }),
    catchError(error => of(userActions.logData(`${error}, subsequentLogin`))),
  );

export const appEpics = [
  loadAppStateEpic,
  checkSessionEpic,
  logAppErrorEpic,
  lazyloadFirebaseEpic,
  subsequentLoginsEpic,
];
