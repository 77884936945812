import {
  AccountIcon,
  AvailabilityIconV2,
  CalendarIcon,
  ChatIcon,
  CollaborationHeartIcon,
  CollaborationIconV2,
  CreditCardIcon,
  HomeIcon,
  MarketingIcon,
  NotificationsIcon,
  ProfileIcon,
  ProgressIcon,
  SettingsIcon,
  StarIcon,
  TAndCIcon,
  UserGroupIconV2,
} from 'components';
import {UserRoles} from 'interfaces';
import {isInPwa, isPwaNotSupported} from 'utils';

import {SvgIcon} from './../Basic/SvgIcon/SvgIcon';
import {LinkProps} from './NavLink';

export type NavMenuItem = {
  name: string;
  route: string;
  routeRegex?: RegExp;
  icon: SvgIcon;
  hideOnMobile?: boolean;
  iconClasses?: string;
};

export enum NavRoles {
  therapist = 'therapist',
  prescriber = 'prescriber',
  member = 'patient',
}

const ProviderLeftNavigationIcons = {
  home: HomeIcon,
  members: UserGroupIconV2,
  calendar: CalendarIcon,
  availability: AvailabilityIconV2,
  messages: ChatIcon,
  collaboration: CollaborationIconV2,
  marketing: MarketingIcon,
  settings: SettingsIcon,
  collaborationHeart: CollaborationHeartIcon,
};

export type RightLinkProps = LinkProps & {
  icon: SvgIcon;
};

type NavMenuItemsListProps = Record<NavRoles, Array<NavMenuItem>>;
type RightNavMenuItemsListProps = Record<NavRoles, Array<RightLinkProps>>;

export const menuUsOnlyPageNames = [
  'In-network',
  'Collaboration',
  'Earn Wellbits',
];

export const filterNavMenuItems = (role: UserRoles, isUsProvider: boolean) => {
  return NavMenuItems[role]
    .filter(
      (option: NavMenuItem) =>
        (!isUsProvider && !menuUsOnlyPageNames.includes(option.name)) ||
        isUsProvider,
    )
    .map((option: NavMenuItem) =>
      !isUsProvider && option.name === 'Members'
        ? {...option, name: 'Patients'}
        : option,
    );
};

export const NavMenuItems: NavMenuItemsListProps = {
  patient: [
    {name: 'Home', route: '/dashboard', icon: HomeIcon, iconClasses: 'w-5 h-5'},
    {
      name: 'Progress',
      route: '/progress',
      icon: ProgressIcon,
      iconClasses: 'w-5 h-5',
    }, // change route
    {
      name: 'Notifications',
      route: '/notifications',
      icon: NotificationsIcon,
      iconClasses: 'w-5 h-5',
    },
    {
      name: 'Calendar',
      route: '/calendar',
      icon: CalendarIcon,
      iconClasses: 'w-5 h-5',
    }, // change route
    {
      name: 'Messages',
      route: '/message',
      icon: ChatIcon,
      iconClasses: 'w-5 h-5',
    },
    {
      name: 'Experiences',
      route: '/experiences',
      icon: StarIcon,
      iconClasses: 'w-5 h-5',
    },
  ],

  therapist: [
    {
      name: 'Home',
      route: '/dashboard',
      icon: ProviderLeftNavigationIcons.home,
    },
    {
      name: 'Members',
      route: '/provider/view-members',
      routeRegex: /member\/.*/,
      icon: ProviderLeftNavigationIcons.members,
    },
    {
      name: 'Notifications',
      route: '/notifications',
      icon: NotificationsIcon,
      iconClasses: 'w-5 h-5',
    },
    {
      name: 'Calendar',
      route: '/provider/calendar',
      icon: ProviderLeftNavigationIcons.calendar,
    },
    {
      name: 'Availability',
      route: '/provider/availability',
      icon: ProviderLeftNavigationIcons.availability,
    },
    {
      name: 'Messages',
      route: '/provider/message',
      icon: ProviderLeftNavigationIcons.messages,
    },
    {
      name: 'Experiences',
      route: '/experiences',
      icon: StarIcon,
      iconClasses: 'w-5 h-5',
    },
    {
      name: 'Earn Wellbits',
      route: '/provider/digital-practice/earnings',
      icon: ProviderLeftNavigationIcons.collaborationHeart,
    },
  ],

  prescriber: [
    {
      name: 'Home',
      route: '/dashboard',
      icon: ProviderLeftNavigationIcons.home,
    },
    {
      name: 'Members',
      route: '/provider/view-members',
      icon: ProviderLeftNavigationIcons.members,
    },
    {
      name: 'Notifications',
      route: '/notifications',
      icon: NotificationsIcon,
      iconClasses: 'w-5 h-5',
    },
    {
      name: 'Calendar',
      route: '/provider/calendar',
      icon: ProviderLeftNavigationIcons.calendar,
    },
    {
      name: 'Availability',
      route: '/provider/availability',
      icon: ProviderLeftNavigationIcons.availability,
    },
    {
      name: 'Messages',
      route: '/provider/message',
      icon: ProviderLeftNavigationIcons.messages,
    },

    {
      name: 'Earn Wellbits',
      route: '/provider/digital-practice/earnings',
      icon: ProviderLeftNavigationIcons.collaborationHeart,
    },
  ],
};

export const RightNavMenuItems = (
  pathname: string,
): RightNavMenuItemsListProps => {
  return {
    [UserRoles.member]: [
      {
        pathname: '/membership',
        linkName: 'Membership',
        current: pathname === '/membership',
        first: true,
        icon: CreditCardIcon,
        classNames: 'pl-10 md:pl-24',
      },
      {
        pathname: '/profile',
        linkName: 'Profile',
        current: pathname === '/profile',
        icon: AccountIcon,
        classNames: 'pl-10 md:pl-24',
      },
      /*{
        pathname: '/share',
        linkName: 'Share',
        current: pathname === '/share',
        icon: ShareIcon,
        classNames:
          'bg-gradient-to-r from-blue-600 to-blue-400 hover:from-blue-600 hover:to-blue-500 pl-10 md:pl-24',
      },*/
      {
        pathname: '/terms-and-conditions',
        linkName: 'Terms and Conditions',
        current: pathname === '/terms-and-conditions',
        icon: TAndCIcon,
        last: isInPwa() || isPwaNotSupported,
        classNames: 'pl-10 md:pl-24',
      },
    ],
    [UserRoles.therapist]: [
      {
        pathname: '/provider/profile',
        linkName: 'Profile & Account Settings',
        current: pathname === '/provider/profile',
        last: false,
        icon: AccountIcon,
        classNames: 'pl-10 md:pl-24',
      },
      {
        pathname: '/provider/digital-practice',
        linkName: 'Digital Practice',
        current: pathname === '/provider/digital-practice',
        icon: AccountIcon,
        classNames: 'pl-10 md:pl-24',
      },
    ],
    [UserRoles.prescriber]: [
      {
        pathname: '/provider/profile',
        linkName: 'Profile & Account Settings',
        current: pathname === '/provider/profile',
        last: false,
        icon: AccountIcon,
        classNames: 'pl-10 md:pl-24',
      },
      {
        pathname: '/provider/digital-practice',
        linkName: 'Digital Practice',
        current: pathname === '/provider/digital-practice',
        icon: AccountIcon,
        classNames: 'pl-10 md:pl-24',
      },
    ],
  };
};

export const IconNavigationMenuItems = (
  pathname: string,
): RightNavMenuItemsListProps => {
  return {
    [UserRoles.member]: [
      {
        pathname: '/dashboard',
        current: pathname === '/dashboard',
        linkName: '',
        classNames: 'pl-6',
        icon: HomeIcon,
        first: true,
      },
      {
        pathname: '/profile',
        linkName: '',
        current: pathname === '/profile',
        classNames: 'pl-6',
        icon: ProfileIcon,
      },
      {
        pathname: '/notifications',
        linkName: '',
        current: pathname === '/notifications',
        icon: NotificationsIcon,
        classNames: 'pl-6',
      },
      /*   {
        pathname: '/messages',
        linkName: '',
        current: pathname === '/messages',
        icon: MessagesIcon,
        classNames: 'pl-6',
      }, */
    ],
    [UserRoles.therapist]: [
      {
        pathname: '/dashboard',
        current: pathname === '/dashboard',
        linkName: '',
        classNames: 'pl-6',
        icon: HomeIcon,
        first: true,
      },
      {
        pathname: '/provider/profile',
        linkName: '',
        current: pathname === '/provider/profile',
        classNames: 'pl-6',
        icon: AccountIcon,
      },
    ],
    [UserRoles.prescriber]: [
      {
        pathname: '/dashboard',
        current: pathname === '/dashboard',
        linkName: '',
        classNames: 'pl-6',
        icon: HomeIcon,
        first: true,
      },
      {
        pathname: '/provider/profile',
        linkName: '',
        current: pathname === '/provider/profile',
        classNames: 'pl-6',
        icon: AccountIcon,
      },
    ],
  };
};
