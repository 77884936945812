import {AppActions, RootState} from 'app/rootReducer';
import Toast from 'components/Basic/Toast';
import {push} from 'connected-react-router';
import {userActions} from 'features/User';
import {Epic} from 'redux-observable';
import {concat, from, of} from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  ignoreElements,
  map,
  mergeMap,
  switchMap,
  tap,
} from 'rxjs/operators';
import {ProviderService} from 'services/api/Provider';

import {providerActions} from '../providerSlice';

import {collaborationEpics} from './Collaboration/CollaborationEpics';
import {colleaguesEpic} from './Collaboration/Colleagues/colleaguesEpics';
import {progressEpics} from './Collaboration/Progress/progressEpics';
import {digitalPracticeLinkEpics} from './DigitalPracticeLinkModal/digitalPracticeLinkEpics';
import {RevenueEpics} from './Revenue/revenueEpics';
import {digitalPracticeActions} from './digitalPracticeSlice';

export const createStripeConnectAccountEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(digitalPracticeActions.createStripeConnectAccount.match),
    switchMap(({payload: {role}}) =>
      from(ProviderService.createStripeConnectAccount(role)).pipe(
        concatMap(({data}) => {
          window.open(data.message, '_blank', 'noopener,noreferrer');
          return of(data).pipe(
            mergeMap(() => [
              digitalPracticeActions.createStripeConnectAccountSuccess({
                onboardUrl: data.message,
              }),
            ]),
          );
        }),
        catchError(() =>
          concat(
            of(digitalPracticeActions.createStripeConnectAccountFailure()),
          ),
        ),
      ),
    ),
  );

export const createStripeConnectAccountFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(digitalPracticeActions.createStripeConnectAccountFailure.match),
    tap(() => {
      Toast({
        type: 'error',
        message: 'Something went wrong, please try again.',
      });
    }),
    ignoreElements(),
  );

export const redirectAuthEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(digitalPracticeActions.reauthenticateStripeOnboarding.match),
    switchMap(({payload}) =>
      from(ProviderService.reauthenticateStripeOnboarding(payload)).pipe(
        concatMap(({message}) => {
          window.location.href = message;
          return of(message).pipe(
            mergeMap(() => [
              digitalPracticeActions.reauthenticateStripeOnboardingSuccess(),
            ]),
          );
        }),
        catchError((message: string) =>
          concat(
            of(
              digitalPracticeActions.reauthenticateStripeOnboardingFailure(
                message,
              ),
            ),
          ),
        ),
      ),
    ),
  );

export const redirectAuthFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(digitalPracticeActions.reauthenticateStripeOnboardingFailure.match),
    tap(({payload: message}) => {
      if (message) {
        Toast({type: 'error', message});
      }
    }),
    map(() => push({pathname: '/provider/profile/revenue'})),
  );

export const completeStripeOnboardingEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(digitalPracticeActions.completeStripeOnboarding.match),
    switchMap(({payload}) => {
      return from(ProviderService.completeStripeOnboarding(payload)).pipe(
        mergeMap(({message: {redirectUrl, user}}) => [
          digitalPracticeActions.completeStripeOnboardingSuccess(),
          userActions.setUser(user),
          push({
            pathname: redirectUrl,
          }),
        ]),
        catchError((message: string) =>
          concat(
            of(digitalPracticeActions.completeStripeOnboardingFailure(message)),
          ),
        ),
      );
    }),
  );

export const completeStripeOnboardingFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(digitalPracticeActions.completeStripeOnboardingFailure.match),
    tap(({payload: message}) => {
      if (message) {
        Toast({type: 'error', message});
      }
    }),
    map(() => push({pathname: '/provider/profile/revenue'})),
  );

export const getWellbitWithrawalHistoryFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(digitalPracticeActions.getWellbitWithdrawalHistoryFailure.match),
    tap(({payload: message}) => {
      if (message) {
        Toast({type: 'error', message});
      }
    }),
    map(() => push({pathname: '/provider/digital-practice/collaboration'})),
  );

export const getWellbitWithdrawalHistoryEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(digitalPracticeActions.getWellbitWithdrawalHistory.match),
    mergeMap(({payload}) =>
      from(ProviderService.getWellbitWithdrawalHistory(payload)).pipe(
        mergeMap(({cashingHistory, hasMore, balanceHistory, nextPage}) => [
          digitalPracticeActions.getWellbitWithdrawalHistorySuccess({
            cashingHistory,
            hasMore,
            nextPage,
            balanceHistory,
          }),
        ]),
        catchError((message: string) =>
          concat(
            of(userActions.setAsyncError({filter: 'provider', message})),
            of(providerActions.getMembersFailure()),
          ),
        ),
      ),
    ),
  );

export const withdrawWellbitsEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(digitalPracticeActions.withdrawWellbits.match),
    switchMap(({payload: {cb, ...data}}) =>
      from(ProviderService.withdrawWellbits(data)).pipe(
        concatMap(({message}) => {
          cb(); // callback to close the "CashWithdraw" modal
          Toast({
            type: 'success',
            message: 'You have successfully cashed out your Wellbits.',
          });
          return of(
            digitalPracticeActions.withdrawWellbitsSuccess({
              message,
              withdrawnAmount: data.amount ?? 0,
            }),
          );
        }),
        catchError((message: string) =>
          concat(of(digitalPracticeActions.withdrawWellbitsFailure(message))),
        ),
      ),
    ),
  );

export const withdrawWellbitsFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(digitalPracticeActions.withdrawWellbitsFailure.match),
    tap(({payload: message}) => {
      Toast({type: 'error', message});
    }),
    ignoreElements(),
  );

export const digitalPracticeEpics = [
  digitalPracticeLinkEpics,
  colleaguesEpic,
  collaborationEpics,
  progressEpics,
  RevenueEpics,
  [
    createStripeConnectAccountEpic,
    createStripeConnectAccountFailureEpic,
    redirectAuthEpic,
    redirectAuthFailureEpic,
    completeStripeOnboardingEpic,
    completeStripeOnboardingFailureEpic,
    getWellbitWithdrawalHistoryEpic,
    getWellbitWithrawalHistoryFailureEpic,
    withdrawWellbitsEpic,
    withdrawWellbitsFailureEpic,
  ],
].flatMap(epic => epic);
