import React, {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import {CloseIcon, CollaborationHeartIcon} from 'components/Basic';
import {
  notificationActions,
  selectAllNotifications,
  selectShowEarnNotificaiton,
} from 'features/Notification';
import {createPortal} from 'react-dom';
import {useDispatch, useSelector} from 'react-redux';

type IProps = {
  onClose: () => void;
  isMobile?: boolean;
};

const WellbitsRewardPortal = (
  {onClose, isMobile = false}: IProps,
  ref: ForwardedRef<HTMLElement>,
) => {
  const dispatch = useDispatch();
  const selectShowEarnNotification = useSelector(selectShowEarnNotificaiton);
  const selectAllNotification = useSelector(selectAllNotifications);
  const containerRef = useRef<HTMLDivElement>(null);
  const targetedElement = ref && (ref as React.RefObject<HTMLElement>).current;

  const calculatePosition = useCallback(() => {
    const containerElem = containerRef.current;
    if (!containerElem || !targetedElement) return;
    const targetedRect = targetedElement.getBoundingClientRect();
    const containerRect = containerElem.getBoundingClientRect();
    containerElem.style.top = '';
    containerElem.style.left = '';
    const position = {
      top: isMobile
        ? `${targetedRect.top - containerRect.height - 30}px`
        : `${targetedRect.top - 25}px`,
      left: isMobile
        ? `${targetedRect.left - containerRect.width + 15}px`
        : `${targetedRect.right + 20}px`,
    };
    containerElem.style.top = position.top;
    containerElem.style.left = position.left;
  }, [targetedElement, isMobile]);

  useEffect(() => {
    if (!targetedElement) return;
    calculatePosition();
    const observer = new MutationObserver(calculatePosition);
    observer.observe(targetedElement, {
      attributes: true,
      childList: true,
      subtree: true,
    });
    const handleResize = () => calculatePosition();
    window.addEventListener('resize', handleResize);
    return () => {
      observer.disconnect();
      window.removeEventListener('resize', handleResize);
    };
  }, [calculatePosition, targetedElement]);

  if (!targetedElement) return null;

  return createPortal(
    <div
      ref={containerRef}
      className={`fixed z-999 min-w-[320px] max-w-[370px] lg:min-w-[521px] lg:max-w-auto p-4 bg-[#F4E4BC] rounded-2xl shadow-lg ${
        selectShowEarnNotification ? '' : 'hidden'
      }`}
    >
      <div className="flex items-center">
        <div className="flex flex-1 items-center justify-between gap-3">
          <CollaborationHeartIcon width={30} height={30} />
          {selectAllNotification.length > 0 && (
            <p className="flex-1 font-montserrat text-sm font-normal text-black">
              {selectAllNotification[0].notification.text}
            </p>
          )}
        </div>
        <div className="ml-4 flex shrink-0">
          <button
            type="button"
            onClick={(event: React.MouseEvent) => {
              dispatch(notificationActions.resetShowEarnNotification());
              onClose();
              event.stopPropagation();
            }}
            className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <span className="sr-only">Close</span>
            <CloseIcon
              width={24}
              height={24}
              strokeColor="text-black bg-[#F4E4BC]"
              strokeWidth={40}
            />
          </button>
        </div>
      </div>
    </div>,
    document.body,
  );
};

export const WellbitsRewardNotification = forwardRef<HTMLElement, IProps>(
  WellbitsRewardPortal,
);
