import {useCallback, useEffect, useState} from 'react';
import {dispatch} from 'app/store';
import {
  selectWellniteAudiosStatus,
  selectWellniteAudioTracks,
} from 'features/Provider/Experiences/WellniteAudios/wellniteAudiosSelectors';
import {wellniteAudiosActions} from 'features/Provider/Experiences/WellniteAudios/wellniteAudiosSlice';
import {SliceStatus} from 'interfaces';
import {useSelector} from 'react-redux';

export const useWellniteAudios = (providerId: string, PAGE_SIZE = 20) => {
  const [currentlyPlayingId, setCurrentlyPlayingId] = useState<string | null>(
    null,
  );
  const handlePlay = (id: string) => {
    setCurrentlyPlayingId(prev => (prev === id ? null : id));
  };

  const {
    audios,
    pagination: {hasNextPage, nextPage},
  } = useSelector(selectWellniteAudioTracks);

  const audiosLoadingStatus = useSelector(selectWellniteAudiosStatus);
  const isLoadingAudios = audiosLoadingStatus === SliceStatus.pending;
  const fetchAudios = useCallback(() => {
    dispatch(
      wellniteAudiosActions.getAudios({
        providerId,
        page: nextPage,
        pageSize: PAGE_SIZE,
      }),
    );
  }, [dispatch, nextPage]);

  useEffect(() => {
    fetchAudios();
  }, []);

  const infiniteScrollConfig = {
    dataLength: audios.length,
    hasMore: hasNextPage,
    next: fetchAudios,
  };
  return {
    audios,
    audiosLoadingStatus,
    handlePlay,
    currentlyPlayingId,
    hasNextPage,
    nextPage,
    fetchAudios,
    infiniteScrollConfig,
    isLoadingAudios,
  };
};
