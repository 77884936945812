import {
  AvailabilityInTimeRange,
  InternationalProviderCredentialFormType,
  InternationalProviderProfileFormType,
  InternationalProviderProfileTwoFormType,
  InternationalProviderUploadIdDocumentFormType,
  LicenseCopyFormData,
  MemberProfile,
  NormalizedMedications,
  PrescriberProfile,
  ProfessionalHeadshotFormData,
  ProfileImageFormData,
  ProviderProfile,
  ProviderRole,
  TherapistProfile,
  UpdateMedicationsFormData,
  UpdateProviderProfileInfoFormData,
  UserRoles,
  WellbitData,
  WellbitTypeTotal,
  WidgetsPatientsDetails,
  WidgetsProviderDetails,
} from 'interfaces';
import {
  ProviderSignupFormData,
  ProviderUpdateForm,
} from 'interfaces/Pages/Provider/Signup.types';
import {
  WellbitHistory,
  WellbitWallet,
} from 'interfaces/Pages/Provider/Wellbit.types';
import {normalize, schema} from 'normalizr';
import {Response} from 'redaxios';
import {serializeAcuityData, toFormData, unwrapAPIError} from 'utils';

import {GetMembersPayload} from '../../../features/Provider/Members/membersActions';
import {requestHandler} from '../HTTP';
import {EndPoints, HttpMethods} from '../HTTP/HTTP.types';

export const medication = new schema.Entity(
  'medications',
  {},
  {
    idAttribute: (value): string => {
      return value.medicationId;
    },
  },
);

const member = new schema.Entity<MemberProfile>(
  'members',
  {},
  {
    idAttribute: (value): string => {
      return value.patientId;
    },
  },
);
const getProviderIdQuery = (providerId?: string) => {
  if (providerId) {
    return `&providerId=${providerId}`;
  }
  return '';
};
export const arrayOfMembers = new schema.Array(member);

export const arrayOfMedications = new schema.Array(medication);

export enum OrderMembersBy {
  AlphaAz = 'alpha_a-z',
  AlphaZa = 'alpha_z-a',
  DateAsc = 'date_asc',
  DateDesc = 'date_desc',
}

export const getMembers = async ({
  role,
  limit,
  orderBy,
}: GetMembersPayload): Promise<{
  normalizedMembers: {[key: string]: MemberProfile};
  hasMore: boolean;
}> => {
  try {
    const res = await requestHandler<{
      message: {
        patientsList: MemberProfile[];
        hasMore: boolean;
      };
    }>({
      method: HttpMethods.GET,
      url: `/api/${role}/${EndPoints.GetMembers}/${limit}?orderBy=${orderBy}` as unknown as EndPoints,
    });

    const {
      message: {patientsList, hasMore},
    } = res.data;

    const normalizedPatientLists = normalize(patientsList, arrayOfMembers);
    return {
      normalizedMembers: normalizedPatientLists.entities?.members ?? {},
      hasMore,
    };
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

export const getMember = async ({
  patientEmail,
  role,
}: {
  patientEmail: string;
  role: UserRoles;
}): Promise<{
  normalizedMembers: {[key: string]: MemberProfile};
  hasMore: boolean;
}> => {
  try {
    const res = await requestHandler<{
      message: MemberProfile;
    }>({
      method: HttpMethods.GET,
      url: `/api/${role}/${EndPoints.GetMember}/${patientEmail}` as unknown as EndPoints,
    });
    const normalizedPatientLists = normalize(
      [res.data.message],
      arrayOfMembers,
    );
    return {
      normalizedMembers: normalizedPatientLists.entities?.members ?? {},
      hasMore: true,
    };
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

export const getMemberById = async ({
  patientId,
  role,
}: {
  patientId: string;
  role: UserRoles;
}): Promise<{
  normalizedMembers: {[key: string]: MemberProfile};
  hasMore: boolean;
}> => {
  try {
    const res = await requestHandler<{
      message: MemberProfile;
    }>({
      method: HttpMethods.GET,
      url: `/api/${role}/${EndPoints.GetMemberById}/${patientId}` as unknown as EndPoints,
    });
    const normalizedPatientLists = normalize(
      [res.data.message],
      arrayOfMembers,
    );
    return {
      normalizedMembers: normalizedPatientLists.entities?.members ?? {},
      hasMore: true,
    };
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

export const getMedications = async (): Promise<
  Response<{message: {medications: NormalizedMedications}}>
> => {
  try {
    const res = await requestHandler<{
      message: {medications: NormalizedMedications};
    }>({
      method: HttpMethods.GET,
      url: EndPoints.GetMedications,
    });
    const normalizedMedications = normalize(
      res.data.message,
      arrayOfMedications,
    );

    res.data.message.medications = {
      ...normalizedMedications,
      filter: 'medications',
    } as NormalizedMedications;

    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const updateProviderProfile = async ({
  role,
  ...data
}: {
  email: string;
  password: string;
  role: UserRoles;
}): Promise<Response<{message: TherapistProfile | PrescriberProfile}>> => {
  try {
    return await requestHandler<
      {message: TherapistProfile | PrescriberProfile},
      typeof data
    >({
      method: HttpMethods.POST,
      url: `/api/${role}${EndPoints.UpdateProviderProfile}` as unknown as EndPoints,
      data,
    });
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const updateProviderPreferredLanguage = async ({
  role,
  ...data
}: {
  role: UserRoles;
  preferredLanguage: string;
}): Promise<Response<{message: TherapistProfile | PrescriberProfile}>> => {
  try {
    return await requestHandler<
      {message: TherapistProfile | PrescriberProfile},
      typeof data
    >({
      method: HttpMethods.POST,
      url: `/api/${role}${EndPoints.UpdateProviderProfile}` as unknown as EndPoints,
      data,
    });
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const updateProviderProfileInfo = async ({
  role,
  ...data
}: {
  role: UserRoles;
  profileInfo: UpdateProviderProfileInfoFormData;
}): Promise<Response<{message: TherapistProfile | PrescriberProfile}>> => {
  try {
    return await requestHandler<
      {message: TherapistProfile | PrescriberProfile},
      typeof data
    >({
      method: HttpMethods.POST,
      url: `/api/${role}/profile/update-profile-info` as unknown as EndPoints,
      data,
    });
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};
const updateProviderProfilePicture = async ({
  image,
  role,
}: {
  image: ProfileImageFormData;
  role: UserRoles;
}): Promise<Response<{message: TherapistProfile | PrescriberProfile}>> => {
  const formData = toFormData(image);
  try {
    return await requestHandler<
      {message: TherapistProfile | PrescriberProfile},
      typeof formData
    >({
      method: HttpMethods.POST,
      url: `/api/${role}${EndPoints.UpdateProviderProfile}` as unknown as EndPoints,
      data: formData,
    });
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

export const searchMembers = async ({
  role,
  searchTerm,
  providerId,
  orderBy,
}: {
  searchTerm: string;
  role: UserRoles;
  providerId?: string;
  orderBy: string;
}): Promise<{
  normalizedMembers: {[key: string]: MemberProfile};
}> => {
  try {
    const res = await requestHandler<{
      message: {
        patientsList: MemberProfile[];
      };
    }>({
      method: HttpMethods.GET,
      url: `/api/${role}/${
        EndPoints.SearchMembers
      }/?searchTerm=${encodeURIComponent(searchTerm)}${getProviderIdQuery(
        providerId,
      )}&role=${role}&orderBy=${orderBy}` as unknown as EndPoints,
    });
    const normalizedPatientLists = normalize(
      {...res.data.message},
      arrayOfMembers,
    );
    return {
      normalizedMembers: normalizedPatientLists.entities?.members ?? {},
    };
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

export const updateMedications = async ({
  ...data
}: UpdateMedicationsFormData & {patientId: string; role: UserRoles}): Promise<
  Response<{message: MemberProfile}>
> => {
  try {
    return await requestHandler<{message: MemberProfile}, typeof data>({
      method: HttpMethods.PUT,
      url: `${EndPoints.UpdatePatientMedication}${data.patientId}` as unknown as EndPoints,
      data,
    });
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

export const signup = async ({
  ...data
}: ProviderSignupFormData): Promise<Response<{message: ProviderProfile}>> => {
  try {
    const fullName = data.fullName.split(' ');
    const firstName = fullName.length ? fullName[0] : '';
    if (fullName.length) {
      fullName.shift();
    }
    const lastName = fullName.length ? fullName.join(' ') : '';
    const providerType =
      data.countryOfResidence && data.countryOfResidence.code !== 'US'
        ? 'therapist'
        : data.selectedProvider;
    const licenseCategory =
      data.countryOfResidence && data.countryOfResidence.code !== 'US'
        ? 'licensed'
        : data.selectedLicenseType;
    const phoneNumber = '+' + data.phoneNumber;
    const mappedData = {
      providerFirstName: firstName,
      providerLastName: lastName,
      providerPersonalEmailId: data.email,
      wellniteEmailId: data.email,
      deelEmailId: data.email,
      password: data.password,
      state: [data.stateOfResidence],
      stateOfResidence: data.stateOfResidence,
      referredByCode: data?.referralCode ?? '',
      providerType: providerType,
      providerLicenseCategory: licenseCategory,
      countryOfResidence: data.countryOfResidence,
      phoneNumber: phoneNumber,
    };
    const response = await requestHandler<
      {message: ProviderProfile},
      typeof mappedData
    >({
      method: HttpMethods.POST,
      url: `/api/${mappedData.providerType}${EndPoints.ProviderRegister}` as unknown as EndPoints,
      data: mappedData,
    });
    return response;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

export const updateProviderData = async ({
  ...data
}: ProviderUpdateForm): Promise<Response<{message: ProviderProfile}>> => {
  try {
    const {formType, step} = data;
    const response = await requestHandler<{
      message: ProviderProfile;
    }>({
      method: HttpMethods.POST,
      url: `/api/${data.role}${EndPoints.ProviderRegister}/${formType}/${step}` as unknown as EndPoints,
      data: data.formData,
    });

    return response;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const getTherapyQuestionnaire = async (
  patientId: string,
): Promise<
  Response<{
    message: any;
  }>
> => {
  try {
    return await requestHandler<{
      message: any;
    }>({
      method: HttpMethods.GET,
      url: `${EndPoints.GetIntakeQTherapyQuestionnaire}?patientId=${patientId}` as unknown as EndPoints,
    });
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const sendCalendarSettingsNotification = async (role: UserRoles) => {
  try {
    await requestHandler({
      method: HttpMethods.POST,
      url: `/api/${role}${EndPoints.CalendarSettingsNotification}` as unknown as EndPoints,
    });

    return;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};
const createStripeConnectAccount = async (
  role: UserRoles,
): Promise<Response<{message: string}>> => {
  try {
    const res = await requestHandler<{
      message: string;
    }>({
      method: HttpMethods.GET,
      url: `/api/${role}${EndPoints.CreateStripeConnectAccount}` as unknown as EndPoints,
    });

    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const reauthenticateStripeOnboarding = async (payload: {
  role: string;
}): Promise<{message: string}> => {
  try {
    const res = await requestHandler<{
      message: string;
    }>({
      method: HttpMethods.GET,
      url: `/api/${payload.role}/${EndPoints.ReauthenticateStripeOnboarding}` as EndPoints,
    });
    return res.data;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const completeStripeOnboarding = async (payload: {
  role: string;
}): Promise<{message: {redirectUrl: string; user: TherapistProfile}}> => {
  try {
    const res = await requestHandler<{
      message: {redirectUrl: string; user: TherapistProfile};
    }>({
      method: HttpMethods.GET,
      url: `/api/${payload.role}/${EndPoints.CompleteStripeOnboarding}` as EndPoints,
    });
    return res.data;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

export const getWellbitsData = async (
  role: UserRoles,
): Promise<WellbitData> => {
  try {
    const res = await requestHandler<{
      message: {
        wallet: {
          wellbitTypeTotalCount: WellbitTypeTotal;
          totalWellbits: number;
        };
        currentAvailableWellbits: number;
      };
    }>({
      method: HttpMethods.GET,
      url: `/api/${role}${EndPoints.GetWellbitsType}` as EndPoints,
    });

    const {
      message: {
        wallet: {wellbitTypeTotalCount, totalWellbits},
        currentAvailableWellbits,
      },
    } = res.data;
    const data: WellbitData = {
      currentAvailableWellbits,
      wellbitsTypeCount: wellbitTypeTotalCount,
      totalWellbits,
    };
    return data;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const getWellbitWithdrawalHistory = async (payload: {
  role?: UserRoles;
  pageNo: number;
  size: number;
  from?: Date | null;
  to?: Date | null;
}): Promise<{
  cashingHistory: WellbitHistory[];
  hasMore: boolean;
  nextPage: number;
  balanceHistory: number;
}> => {
  try {
    const url =
      payload.from && payload.to
        ? `/api/${payload.role}/${EndPoints.GetWellbitWithdrawalHistory}?pageNo=${payload.pageNo}&size=${payload.size}&from=${payload.from}&to=${payload.to}`
        : `/api/${payload.role}/${EndPoints.GetWellbitWithdrawalHistory}?pageNo=${payload.pageNo}&size=${payload.size}`;
    const res = await requestHandler<{
      message: {
        result: WellbitHistory[];
        hasMore: boolean;
        nextPage: number;
        balanceHistory: number;
      };
    }>({
      method: HttpMethods.GET,
      url: url as EndPoints,
    });
    const {result, hasMore, balanceHistory, nextPage} = res.data.message;
    return {
      cashingHistory: result,
      hasMore,
      nextPage,
      balanceHistory,
    };
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};
const withdrawWellbits = async ({
  role,
  amount,
}: {
  role: ProviderRole;
  amount?: number;
}): Promise<{message: WellbitWallet}> => {
  try {
    const res = await requestHandler<{
      message: WellbitWallet;
    }>({
      method: HttpMethods.POST,
      url: `/api/${role}${EndPoints.WithdrawWellbits}` as unknown as EndPoints,
      data: {amount},
    });
    return res.data;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

export const updateProfessionalHeadshot = async (
  professionalHeadshotForm: ProfessionalHeadshotFormData,
): Promise<Response<{message: string}>> => {
  const formData = toFormData(professionalHeadshotForm);
  try {
    return await requestHandler<{message: string}, typeof formData>({
      method: HttpMethods.POST,
      url: `/api/${professionalHeadshotForm.role}${EndPoints.UpdateProviderProfessionalHeadshot}` as unknown as EndPoints,
      data: formData,
    });
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

export const updateProviderResume = async (
  resumeForm: ProfessionalHeadshotFormData,
): Promise<Response<{message: string}>> => {
  const formData = toFormData(resumeForm);
  try {
    return await requestHandler<{message: string}, typeof formData>({
      method: HttpMethods.POST,
      url: `/api/${resumeForm.role}${EndPoints.UpdateProviderResume}` as unknown as EndPoints,
      data: formData,
    });
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

export const uploadLicenseCopy = async (
  licenseCopyForm: LicenseCopyFormData,
): Promise<Response<{message: TherapistProfile | PrescriberProfile}>> => {
  const formData = toFormData(licenseCopyForm);
  try {
    return await requestHandler<
      {message: TherapistProfile | PrescriberProfile},
      typeof formData
    >({
      method: HttpMethods.POST,
      url: `/api/${licenseCopyForm.role}${EndPoints.UploadProviderLicenseCopy}` as unknown as EndPoints,
      data: formData,
    });
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const changeProviderFromFirstTime = async (
  role: UserRoles,
): Promise<{
  message: TherapistProfile | PrescriberProfile;
}> => {
  try {
    const res = await requestHandler<{
      message: TherapistProfile | PrescriberProfile;
    }>({
      method: HttpMethods.GET,
      url: `/api/${role}${EndPoints.ChangeProviderFromFirstTime}` as EndPoints,
    });
    return res.data;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const changeOnboardingVideoStatus = async (
  role: UserRoles,
): Promise<{
  message: TherapistProfile | PrescriberProfile;
}> => {
  try {
    const res = await requestHandler<{
      message: TherapistProfile | PrescriberProfile;
    }>({
      method: HttpMethods.GET,
      url: `/api/${role}${EndPoints.ChangeOnboardingVideoStatus}` as EndPoints,
    });
    return res.data;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const unsubscribeOnboardingReminder = async ({
  role,
  email,
}: {
  role: UserRoles;
  email: string;
}): Promise<{
  message: string;
}> => {
  try {
    const res = await requestHandler<{
      message: string;
    }>({
      method: HttpMethods.POST,
      url: `/api/${role}${EndPoints.UnsubscribeOnboardingReminder}` as EndPoints,
      data: {
        email,
        role,
      },
    });
    return res.data;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const completeOnboarding = async (
  role: UserRoles,
): Promise<{
  message: ProviderProfile;
}> => {
  try {
    const res = await requestHandler<{
      message: ProviderProfile;
    }>({
      method: HttpMethods.POST,
      url: `/api/${role}${EndPoints.CompleteOnboarding}` as EndPoints,
    });
    return res.data;
  } catch (error) {
    const errorValue = unwrapAPIError(
      error.data?.errors?.length ? error.data.errors[0] : error,
    );
    return Promise.reject(errorValue);
  }
};

const updateProviderNpi = async (data: {
  providerNpi: string;
  role: UserRoles;
}): Promise<{
  message: TherapistProfile | PrescriberProfile;
}> => {
  try {
    const res = await requestHandler<{
      message: TherapistProfile | PrescriberProfile;
    }>({
      method: HttpMethods.POST,
      url: `/api/${data?.role}${EndPoints.UpdateProviderNpi}` as EndPoints,
      data,
    });
    return res.data;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const skipCAQHForm = async (
  role: UserRoles,
): Promise<{
  message: TherapistProfile | PrescriberProfile;
}> => {
  try {
    const res = await requestHandler<{
      message: TherapistProfile | PrescriberProfile;
    }>({
      method: HttpMethods.POST,
      url: `/api/${role}${EndPoints.SkipCAQHForm}` as EndPoints,
    });
    return res.data;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const sendAvailabilityUpdateNotification = async ({
  availabilityData,
  role,
}: {
  availabilityData: AvailabilityInTimeRange;
  role: ProviderRole;
}) => {
  try {
    const dataToSend = serializeAcuityData(availabilityData!);

    await requestHandler({
      method: HttpMethods.POST,
      url: `/api/${role}${EndPoints.AvailabilityUpdateNotification}` as unknown as EndPoints,
      data: dataToSend,
    });

    return;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const updateInternationalProviderCredentialsData = async (
  role: UserRoles,
  formData: InternationalProviderCredentialFormType,
): Promise<Response<{message: TherapistProfile}>> => {
  try {
    const {mentalHealthRole, hasLegalActions, legalActions, ...data} = formData;

    return await requestHandler<{
      message: TherapistProfile;
    }>({
      method: HttpMethods.POST,
      url: `/api/${role}${EndPoints.UpdateInternationalProviderCredentialsData}` as unknown as EndPoints,
      data: {
        mentalHealthRole,
        hasLegalActions,
        legalActions,
        credentials: [{...data}],
      },
    });
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const updateInternationalProviderProfileData = async (
  role: UserRoles,
  data: InternationalProviderProfileFormType,
): Promise<Response<{message: TherapistProfile}>> => {
  try {
    return await requestHandler<{
      message: TherapistProfile;
    }>({
      method: HttpMethods.POST,
      url: `/api/${role}${EndPoints.UpdateInternationalProviderProfileData}` as unknown as EndPoints,
      data,
    });
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};
const updateInternationalProviderProfileTwoData = async (
  role: UserRoles,
  data: InternationalProviderProfileTwoFormType,
): Promise<Response<{message: TherapistProfile}>> => {
  try {
    return await requestHandler<{
      message: TherapistProfile;
    }>({
      method: HttpMethods.POST,
      url: `/api/${role}${EndPoints.UpdateInternationalProviderProfileTwoData}` as unknown as EndPoints,
      data,
    });
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

export const uploadInternationalProviderIdDocument = async (
  role: UserRoles,
  data: InternationalProviderUploadIdDocumentFormType,
): Promise<
  Response<{
    message: TherapistProfile | PrescriberProfile;
  }>
> => {
  if (!data) {
    return Promise.reject('Please upload your ID documents');
  }

  const formData = toFormData(data);
  try {
    return await requestHandler<
      {message: TherapistProfile | PrescriberProfile},
      typeof formData
    >({
      method: HttpMethods.POST,
      url: `/api/${role}${EndPoints.UploadInternationalProviderIdDocument}` as unknown as EndPoints,
      data: formData,
    });
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

export const sendInternationalProviderAgreedToTerms = async (
  role: UserRoles,
): Promise<
  Response<{
    message: TherapistProfile | PrescriberProfile;
  }>
> => {
  try {
    return await requestHandler<{
      message: TherapistProfile | PrescriberProfile;
    }>({
      method: HttpMethods.POST,
      url: `/api/${role}${EndPoints.SendInternationalProviderAgreedToTerms}` as unknown as EndPoints,
      data: {},
    });
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const verifyHuman = async ({
  role,
  imageUrl,
}: {
  role: UserRoles;
  imageUrl: string;
}): Promise<{
  message: boolean;
}> => {
  try {
    const res = await requestHandler<{
      message: boolean;
    }>({
      method: HttpMethods.POST,
      url: `/api/${role}${EndPoints.VerifyHuman}` as EndPoints,
      data: {imageUrl},
    });
    return res.data;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

export const getWidgetPatientsDetails = async ({
  role,
}: {
  role: UserRoles;
}): Promise<{
  message: WidgetsPatientsDetails;
}> => {
  try {
    const res = await requestHandler<{message: WidgetsPatientsDetails}>({
      method: HttpMethods.GET,
      url: `/api/${role}/${EndPoints.GetWidgetPatientsDetails}` as unknown as EndPoints,
    });

    return res.data;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

export const getWidgetProviderDetails = async ({
  role,
}: {
  role: UserRoles;
}): Promise<{
  message: WidgetsProviderDetails;
}> => {
  try {
    const res = await requestHandler<{message: WidgetsProviderDetails}>({
      method: HttpMethods.GET,
      url: `/api/${role}/${EndPoints.GetWidgetProviderDetails}` as unknown as EndPoints,
    });

    return res.data;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

export const setDigitalPracticeSessionRate = async ({
  role,
  min,
  max,
}: {
  role: ProviderRole;
  min: number;
  max: number;
}): Promise<{
  message: string;
}> => {
  try {
    const res = await requestHandler<{
      message: string;
    }>({
      method: HttpMethods.PATCH,
      url: `/api/${role}${EndPoints.DigitalPracticeSessionRate}` as EndPoints,
      data: {min, max},
    });
    return res.data;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

// Update provider compensation status
export const updateProviderCompensationModalStatus = async ({
  role,
  hasShownMonthlyCompensationModal,
}: {
  role: UserRoles;
  hasShownMonthlyCompensationModal: boolean;
}): Promise<
  Response<{
    message: TherapistProfile | PrescriberProfile;
  }>
> => {
  try {
    return await requestHandler<{
      message: TherapistProfile | PrescriberProfile;
    }>({
      method: HttpMethods.POST,
      url: `/api/${role}${EndPoints.UpdateProviderCompensationModalStatus}` as unknown as EndPoints,
      data: {
        hasShownMonthlyCompensationModal,
      },
    });
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

export const ProviderService = {
  getMembers,
  getMember,
  getMemberById,
  getMedications,
  updateProviderProfile,
  updateProviderProfilePicture,
  searchMembers,
  updateMedications,
  getTherapyQuestionnaire,
  sendCalendarSettingsNotification,
  createStripeConnectAccount,
  reauthenticateStripeOnboarding,
  completeStripeOnboarding,
  getWellbitsData,
  getWellbitWithdrawalHistory,
  withdrawWellbits,
  signup,
  updateProfessionalHeadshot,
  changeProviderFromFirstTime,
  updateProviderNpi,
  updateProviderData,
  uploadLicenseCopy,
  skipCAQHForm,
  changeOnboardingVideoStatus,
  sendAvailabilityUpdateNotification,
  updateProviderResume,
  completeOnboarding,
  updateProviderPreferredLanguage,
  updateProviderProfileInfo,
  updateInternationalProviderCredentialsData,
  updateInternationalProviderProfileData,
  updateInternationalProviderProfileTwoData,
  uploadInternationalProviderIdDocument,
  sendInternationalProviderAgreedToTerms,
  verifyHuman,
  getWidgetPatientsDetails,
  getWidgetProviderDetails,
  setDigitalPracticeSessionRate,
  unsubscribeOnboardingReminder,
  updateProviderCompensationModalStatus,
};
