import {
  Audio,
  AudiosPaginationType,
  CloneVoiceArgs,
  CloneVoiceResponse,
  ConvertToAudioArgs,
  ConvertToAudioResponse,
  GenerateAudioScriptArgs,
  GenerateAudioScriptResponse,
  GenerateAudioSuggestionsArgs,
  GenerateAudioSuggestionsResponse,
  GenerateCoverImageArgs,
  GenerateCoverImageResponse,
  GetJobStatusArgs,
  GetJobStatusResponse,
  JobStatusType,
  RegenerateAudioParagraphArgs,
  RegenerateAudioParagraphResponse,
  RegenerateAudioTrackArgs,
  RegenerateAudioTrackResponse,
  SubmitAudioArgs,
  SubmitAudioResponse,
} from 'interfaces';

import {createAction} from '@reduxjs/toolkit';

export const cloneVoice = createAction<
  CloneVoiceArgs & {
    onSuccess?: (data: CloneVoiceResponse) => void;
  }
>('CloneVoice');

export const cloneVoiceSuccess =
  createAction<CloneVoiceResponse>('CloneVoiceSuccess');

export const cloneVoiceFailure = createAction<string>('CloneVoiceFailure');

export const getAudios = createAction<{
  providerId: string;
  page: number;
  pageSize: number;
}>('GetAudios');

export const getAudiosSuccess = createAction<{
  audios: Audio[];
  pagination: AudiosPaginationType;
}>('GetAudiosSuccess');

export const getAudiosFailure = createAction<string>('GetAudiosFailure');

export const generateAudioScript = createAction<
  GenerateAudioScriptArgs & {
    onSuccess?: (data: GenerateAudioScriptResponse) => void;
  }
>('GenerateAudioScript');

export const generateAudioScriptSuccess =
  createAction<GenerateAudioScriptResponse>('GenerateAudioScriptSuccess');

export const generateAudioScriptFailure = createAction<string>(
  'GenerateAudioScriptFailure',
);

export const convertToAudio = createAction<
  ConvertToAudioArgs & {
    onSuccess?: (data: ConvertToAudioResponse) => void;
  }
>('ConvertToAudio');

export const convertToAudioSuccess = createAction<ConvertToAudioResponse>(
  'ConvertToAudioSuccess',
);

export const convertToAudioFailure = createAction<string>(
  'ConvertToAudioFailure',
);

export const getJobStatus = createAction<
  GetJobStatusArgs & {
    onSuccess?: (data: GetJobStatusResponse) => void;
  }
>('GetJobStatus');

export const getJobStatusSuccess = createAction<
  GetJobStatusResponse & {type: JobStatusType}
>('GetJobStatusSuccess');

export const getJobStatusFailure = createAction<string>('GetJobStatusFailure');

export const regenerateAudioParagraph = createAction<
  RegenerateAudioParagraphArgs & {
    onSuccess?: (data: RegenerateAudioParagraphResponse) => void;
  }
>('RegenerateAudioParagraph');

export const regenerateAudioParagraphSuccess =
  createAction<RegenerateAudioParagraphResponse>(
    'RegenerateAudioParagraphSuccess',
  );

export const regenerateAudioParagraphFailure = createAction<string>(
  'RegenerateAudioParagraphFailure',
);

export const regenerateCoverImage = createAction<GenerateCoverImageArgs>(
  'RegenerateCoverImage',
);

export const regenerateCoverImageSuccess =
  createAction<GenerateCoverImageResponse>('RegenerateCoverImageSuccess');

export const regenerateCoverImageFailure = createAction<string>(
  'RegenerateCoverImageFailure',
);

export const generateAudioSuggestions = createAction<
  GenerateAudioSuggestionsArgs & {
    onSuccess?: (data: GenerateAudioSuggestionsResponse) => void;
  }
>('GenerateAudioSuggestions');

export const generateAudioSuggestionsSuccess =
  createAction<GenerateAudioSuggestionsResponse>(
    'GenerateAudioSuggestionsSuccess',
  );

export const generateAudioSuggestionsFailure = createAction<string>(
  'GenerateAudioSuggestionsFailure',
);

export const submitAudio = createAction<SubmitAudioArgs>('SubmitAudio');

export const submitAudioSuccess =
  createAction<SubmitAudioResponse>('SubmitAudioSuccess');

export const submitAudioFailure = createAction<string>('SubmitAudioFailure');

export const regenerateAudioTrack = createAction<
  RegenerateAudioTrackArgs & {
    onSuccess?: (data: RegenerateAudioTrackResponse) => void;
  }
>('RegenerateAudioTrack');

export const regenerateAudioTrackSuccess =
  createAction<RegenerateAudioTrackResponse>('RegenerateAudioTrackSuccess');

export const regenerateAudioTrackFailure = createAction<string>(
  'RegenerateAudioTrackFailure',
);

export const deleteAudio = createAction<{
  audioId: string;
  onSuccess: () => void;
}>('DeleteAudio');

export const deleteAudioSuccess = createAction<{audioId: string}>(
  'DeleteAudioSuccess',
);

export const deleteAudioFailure = createAction<string>('DeleteAudioFailure');

export const generateAudioCoverImage = createAction<GenerateCoverImageArgs>(
  'GenerateAudioCoverImage',
);

export const generateAudioCoverImageSuccess =
  createAction<GenerateCoverImageResponse>('GenerateAudioCoverImageSuccess');

export const generateAudioCoverImageFailure = createAction<string>(
  'GenerateAudioCoverImageFailure',
);

export const resetWellniteAudios = createAction('ResetWellniteAudios');
