import {RootState} from 'app/rootReducer';

import {createSelector} from '@reduxjs/toolkit';

import {notificationsAdapter, NotificationState} from './notificationSlice';

function getNotification(state: RootState): NotificationState {
  return state.notification;
}

export const selectUnreadNotificationsCountByType = (
  notificationType: string,
) =>
  createSelector(getNotification, notification =>
    notification[notificationType]
      ? notification[notificationType].unreadCount
      : 0,
  );

export const selectNotificationsEntitiesByType = (notificationType: string) =>
  createSelector(getNotification, notification =>
    notification[notificationType]
      ? notificationsAdapter
          .getSelectors()
          .selectEntities(notification[notificationType])
      : {},
  );

export const selectNotificationIdsByType = (notificationType: string) =>
  createSelector(getNotification, notification => {
    const normalizedState = notification[notificationType];

    return normalizedState
      ? notificationsAdapter.getSelectors().selectIds(normalizedState)
      : [];
  });

export const selectNotificationPaginationDataByType = (
  notificationType: string,
) =>
  createSelector(getNotification, notification =>
    notification[notificationType]
      ? {
          hasMore: notification[notificationType].hasMore,
          nextPage: notification[notificationType].nextPage,
          total: notification[notificationType].total,
        }
      : {hasMore: false, nextPage: null, total: 0},
  );

export const selectUnreadNotificationsCount = createSelector(
  getNotification,
  notification => notification.unreadCount,
);

export const selectShowEarnNotificaiton = createSelector(
  getNotification,
  notification => notification.showEarnNotification,
);

/**
 * Get notification pagination data
 */
export const selectNotificationPaginationData = createSelector(
  getNotification,
  notification => ({
    hasMore: notification.hasMore,
    nextPage: notification.nextPage,
    total: notification.total,
  }),
);

export const {
  selectAll: selectAllNotifications,
  selectEntities: selectNotificationsEntities,
  selectById: selectNotificationById,
} = notificationsAdapter.getSelectors<RootState>(state => {
  return state.notification;
});
