import {NotificationAPiResponse} from 'interfaces';
import {Response} from 'redaxios';
import {unwrapAPIError} from 'utils';

import {requestHandler} from '../HTTP';
import {EndPoints, HttpMethods} from '../HTTP/HTTP.types';

const updateNotification = async (
  data: string,
): Promise<Response<{message: string}>> => {
  try {
    const res = await requestHandler<{message: string}>({
      method: HttpMethods.POST,
      url: `${EndPoints.UpdateNotification}/?notificationId=${data}` as unknown as EndPoints,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const deleteNotification = async (
  data: string,
): Promise<Response<{message: string}>> => {
  try {
    const res = await requestHandler<{message: string}>({
      method: HttpMethods.DELETE,
      url: `${EndPoints.UpdateNotification}/?notificationId=${data}` as unknown as EndPoints,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const getNotifications = async ({
  page,
  limit,
  notificationType,
}: {
  page: number;
  limit: number;
  notificationType?: string;
}): Promise<
  Response<{
    message: NotificationAPiResponse;
  }>
> => {
  try {
    const queryParams = new URLSearchParams({
      page: String(page),
      limit: String(limit),
    });

    if (notificationType) {
      queryParams.append('notificationType', notificationType);
    }

    return await requestHandler<{message: NotificationAPiResponse}>({
      method: HttpMethods.GET,
      url: `${
        EndPoints.GetNotifications
      }?${queryParams.toString()}` as unknown as EndPoints,
    });
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

export const NotificationService = {
  updateNotification,
  deleteNotification,
  getNotifications,
};
