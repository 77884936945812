import * as Yup from 'yup';

export const CancellationReasonSchema = Yup.object()
  .shape({
    cancellationReason: Yup.string().required(
      'Your feedback is important, please provide a value',
    ),
  })
  .required();

export const CancellationAppointmentsCountSchema = Yup.object()
  .shape({
    appointmentsCount: Yup.string().required(
      'Your feedback is important, please provide a value',
    ),
  })
  .required();

export const CancellationHealthInsuranceSchema = Yup.object()
  .shape({
    hasHealthInsurance: Yup.string().required(
      'Your feedback is important, please provide a value',
    ),
  })
  .required();

export const CancellationAnxDepInThePastSchema = Yup.object()
  .shape({
    treatedForAnxDepInThePast: Yup.string().required(
      'Your feedback is important, please provide a value',
    ),
  })
  .required();

export const CancellationDoctorRatingSchema = Yup.object()
  .shape({
    providerDoctorRating: Yup.number()
      .required('Your feedback is important, please provide a value')
      .test(
        'not-zero',
        'Your feedback is important, please provide a value',
        value => value !== 0,
      ),
  })
  .required();

export const CancellationTakingMedsForAnxDepSchema = Yup.object()
  .shape({
    currentlyTakingMedsForAnxDep: Yup.string().required(
      'Your feedback is important, please provide a value',
    ),
  })
  .required();

export const CancellationSupportRatingSchema = Yup.object()
  .shape({
    customerSupportRating: Yup.number()
      .required('Your feedback is important, please provide a value')
      .test(
        'not-zero',
        'Your feedback is important, please provide a value',
        value => value !== 0,
      ),
  })
  .required();

export const CancellationImproveExperienceSchema = Yup.object()
  .shape({
    improveExperience: Yup.array()
      .of(Yup.string())
      .required('Your feedback is important, please provide a value'),
  })
  .required();

export const CancellationAdditionalDetailsSchema = Yup.object()
  .shape({
    additionalDetails: Yup.string().required(
      'Your feedback is important, please provide a value',
    ),
  })
  .required();
