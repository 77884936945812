import {userActions} from 'features/User/userSlice';
import {
  NotificationAPiResponse,
  SliceStatus,
  TNotification,
  UpdateNotificationFormData,
} from 'interfaces';

import {
  ActionReducerMapBuilder,
  createAction,
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';

interface NotificationStateType extends EntityState<TNotification> {
  error: string;
  status: SliceStatus;
  unreadCount: number;
  hasMore: boolean;
  nextPage: number | null;
  total: number;
  showEarnNotification: boolean;
  [key: string]: any;
}

const openNotificationState = createAction<{
  notificationId: string;
  entityId: string;
  changes: {state: 'read'};
  notificationType?: string;
}>('openNotificationState');
const openNotificationStateFailure = createAction(
  'openNotificationStateFailure',
);

const updateShareRequestNotification = createAction<
  {
    notificationId: string;
    entityId: string;
    postShared?: string;
    bookmarkType?: string;
    referencePath?: string;
  } & UpdateNotificationFormData
>('updateShareRequestNotification');
const updateShareRequestNotificationFailure = createAction(
  'updateShareRequestNotificationFailure',
);

const updateShareResponseNotification = createAction<{
  notificationId: string;
  entityId: string;
  caption?: string;
  postId: string;
}>('updateShareResponseNotification');

const deleteNotification = createAction<{
  notificationId: string;
  entityId: string;
}>('deleteNotification');
const deleteNotificationFailure = createAction('deleteNotificationFailure');
const deleteReceivedNotification = createAction<string>(
  'deleteReceivedNotification',
);
export const getNotifications = createAction<{
  page: number;
  limit: number;
  notificationType?: string;
}>('getNotifications');
export const getNotificationsSuccess = createAction('getNotificationsSuccess');
export const getNotificationsFailure = createAction('getNotificationsFailure');

export const notificationsAdapter = createEntityAdapter<TNotification>({
  selectId: (value): string => {
    return value._id;
  },
  sortComparer: (a, b) => b._id.localeCompare(a._id),
});

const notificationSlice = createSlice({
  name: 'notification',
  initialState: notificationsAdapter.getInitialState({
    error: '',
    status: SliceStatus.idle,
    unreadCount: 0,
    hasMore: false,
    nextPage: null,
    total: 0,
    showEarnNotification: false,
  }) as NotificationStateType,
  reducers: {
    notificationUpdated(
      state,
      action: PayloadAction<{
        id: string;
        changes: Record<string, string | Record<string, string>>;
        notificationType?: string;
      }>,
    ) {
      const {id, changes, notificationType} = action.payload;

      if ('data' in changes) {
        const {
          changes: {data, ...rest},
        } = action.payload;
        const rootChanges = {
          ...rest,
          notification: {
            text: state.entities[id]?.notification.text || '',
            data: {
              ...state.entities[id]?.notification.data,
              ...(data as Record<string, string>),
            },
          },
        };
        notificationsAdapter.updateOne(state, {id, changes: rootChanges});
      } else {
        notificationsAdapter.updateOne(state, action.payload);
      }

      if (changes?.state === 'read') {
        state.unreadCount = Math.max(0, state.unreadCount - 1);
      }

      if (notificationType && state[notificationType]?.entities[id]) {
        if ('data' in changes) {
          const {
            changes: {data, ...rest},
          } = action.payload;
          const notificationTypeChanges = {
            ...rest,
            notification: {
              text:
                state[notificationType].entities[id]?.notification.text || '',
              data: {
                ...state[notificationType].entities[id]?.notification.data,
                ...(data as Record<string, string>),
              },
            },
          };
          notificationsAdapter.updateOne(state[notificationType], {
            id,
            changes: notificationTypeChanges,
          });
        } else {
          notificationsAdapter.updateOne(
            state[notificationType],
            action.payload,
          );
        }

        if (changes?.state === 'read') {
          state[notificationType].unreadCount = Math.max(
            0,
            state[notificationType].unreadCount - 1,
          );
        }
      }
    },
    notificationDeleted(state, action: PayloadAction<string>) {
      if (state.entities[action.payload]?.state === 'unread') {
        state.unreadCount -= 1;
      }
      notificationsAdapter.removeOne(state, action.payload);
    },
    notificationFailedToDelete(
      state,
      action: PayloadAction<TNotification | undefined>,
    ) {
      if (action.payload) {
        notificationsAdapter.addOne(state, action.payload);
      }
    },
    resetShowEarnNotification(state) {
      state.showEarnNotification = false;
    },
    notificationReceived(
      state,
      action: PayloadAction<{
        notification: TNotification;
        notificationType?: string;
      }>,
    ) {
      const {notification, notificationType} = action.payload;

      notificationsAdapter.addOne(state, notification);
      state.unreadCount += 1;

      if (notificationType === 'wellbits_created') {
        state.showEarnNotification = true;
      }

      if (notificationType && state[notificationType]) {
        notificationsAdapter.addOne(state[notificationType], notification);
        state[notificationType].unreadCount += 1;
      }
    },
    notificationsReceived(
      state,
      action: PayloadAction<
        NotificationAPiResponse & {notificationType?: string}
      >,
    ) {
      const {
        notifications,
        unreadCount,
        hasMore,
        nextPage,
        total,
        notificationType,
      } = action.payload;

      if (notificationType) {
        if (!state[notificationType]) {
          state[notificationType] = notificationsAdapter.getInitialState({
            unreadCount: 0,
            hasMore: true,
            nextPage: null,
            total: 0,
          });
        }

        const updatedState = notificationsAdapter.upsertMany(
          {...state[notificationType]},
          notifications,
        );

        state[notificationType] = {
          ...updatedState,
          unreadCount: unreadCount ?? state[notificationType].unreadCount,
          hasMore,
          nextPage,
          total,
        };
      } else {
        notificationsAdapter.upsertMany(state, notifications);
        state.unreadCount = unreadCount ?? state.unreadCount;
        state.hasMore = hasMore;
        state.nextPage = nextPage;
        state.total = total;
      }
    },
  },
  extraReducers: (
    builder: ActionReducerMapBuilder<
      EntityState<TNotification> & {
        error: string;
        status: SliceStatus;
        unreadCount: number;
        hasMore: boolean;
        nextPage: number | null;
        total: number;
      }
    >,
  ) => {
    builder
      .addCase(getNotifications, state => {
        state.status = SliceStatus.pending;
      })
      .addCase(getNotificationsSuccess, state => {
        state.status = SliceStatus.resolved;
      })
      .addCase(getNotificationsFailure, state => {
        state.status = SliceStatus.rejected;
      })
      .addCase(
        userActions.stateLoadingDone,
        (_state, action) => action.payload.state.notification,
      )
      .addCase(userActions.setAsyncError, (state, action) => {
        state.error =
          action.payload.filter === 'notification'
            ? action.payload.message
            : state.error;
      })
      .addCase(userActions.resetAsyncError, (state, action) => {
        state.error = action.payload === 'notification' ? '' : state.error;
      })
      .addDefaultCase(state => state);
  },
});

export const {
  reducer: notificationReducer,
  name: notificationReducerName,
  actions: {
    notificationUpdated,
    notificationsReceived,
    notificationDeleted,
    notificationFailedToDelete,
    notificationReceived,
    resetShowEarnNotification,
  },
} = notificationSlice;

export type TNotificationActions =
  | ReturnType<typeof openNotificationState>
  | ReturnType<typeof openNotificationStateFailure>
  | ReturnType<typeof deleteNotification>
  | ReturnType<typeof deleteNotificationFailure>
  | ReturnType<typeof deleteReceivedNotification>
  | ReturnType<typeof updateShareRequestNotification>
  | ReturnType<typeof updateShareRequestNotificationFailure>
  | ReturnType<typeof updateShareResponseNotification>
  | ReturnType<typeof notificationUpdated>
  | ReturnType<typeof notificationDeleted>
  | ReturnType<typeof notificationFailedToDelete>
  | ReturnType<typeof notificationReceived>
  | ReturnType<typeof notificationsReceived>
  | ReturnType<typeof getNotifications>
  | ReturnType<typeof getNotificationsSuccess>
  | ReturnType<typeof getNotificationsFailure>
  | ReturnType<typeof resetShowEarnNotification>;

export const notificationActions = {
  openNotificationState,
  openNotificationStateFailure,
  deleteNotification,
  deleteNotificationFailure,
  deleteReceivedNotification,
  updateShareRequestNotification,
  updateShareRequestNotificationFailure,
  updateShareResponseNotification,
  notificationUpdated,
  notificationReceived,
  notificationsReceived,
  notificationDeleted,
  notificationFailedToDelete,
  getNotifications,
  getNotificationsSuccess,
  getNotificationsFailure,
  resetShowEarnNotification,
};

export type NotificationState = ReturnType<typeof notificationReducer>;
