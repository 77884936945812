import React from 'react';
import {SvgIconProps} from 'components/Basic/SvgIcon/SvgIcon';

export const AudioIcon: React.FC<SvgIconProps> = ({
  width = 27,
  height = 27,
  fillColor = 'none',
  strokeWidth = 1,
  classes = '',
  strokeColor = 'text-[#FFF5F9]',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    strokeWidth={strokeWidth}
    viewBox="0 0 24 22"
    fill={fillColor}
    className={`stroke-current ${strokeColor} ${classes}`}
  >
    <rect
      x="0.5"
      y="0.0703125"
      width="3"
      height="8.92871"
      rx="1.5"
      fill="url(#paint0_linear_8883_3779)"
    />
    <rect
      x="10.5"
      y="5.17188"
      width="3"
      height="8.92871"
      rx="1.5"
      fill="url(#paint1_linear_8883_3779)"
    />
    <rect
      x="20.5"
      y="0.0703125"
      width="3"
      height="8.92871"
      rx="1.5"
      fill="url(#paint2_linear_8883_3779)"
    />
    <path
      d="M5.5 1.57031C5.5 0.741885 6.17157 0.0703125 7 0.0703125C7.82843 0.0703125 8.5 0.741885 8.5 1.57031V16.4277C8.5 17.2562 7.82843 17.9277 7 17.9277C6.17157 17.9277 5.5 17.2562 5.5 16.4277V1.57031Z"
      fill="url(#paint3_linear_8883_3779)"
    />
    <path
      d="M15.5 1.57031C15.5 0.741885 16.1716 0.0703125 17 0.0703125C17.8284 0.0703125 18.5 0.741885 18.5 1.57031V16.4277C18.5 17.2562 17.8284 17.9277 17 17.9277C16.1716 17.9277 15.5 17.2562 15.5 16.4277V1.57031Z"
      fill="url(#paint4_linear_8883_3779)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_8883_3779"
        x1="2"
        y1="0.0703125"
        x2="2"
        y2="8.99902"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="1" stopColor="#FCE7F1" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_8883_3779"
        x1="12"
        y1="5.17187"
        x2="12"
        y2="14.1006"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="1" stopColor="#FCE7F1" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_8883_3779"
        x1="22"
        y1="0.0703125"
        x2="22"
        y2="8.99902"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="1" stopColor="#FCE7F1" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_8883_3779"
        x1="7"
        y1="0.0703125"
        x2="7"
        y2="17.9277"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="1" stopColor="#FCE7F1" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_8883_3779"
        x1="17"
        y1="0.0703125"
        x2="17"
        y2="17.9277"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="1" stopColor="#FCE7F1" />
      </linearGradient>
    </defs>
  </svg>
);
