import React, {useEffect, useState} from 'react';
import {dispatch} from 'app/store';
import classNames from 'classnames';
import {LoadingSpinner, RefreshIcon} from 'components';
import {TimerProgressBar} from 'components/Basic/TimerProgressBar';
import {SliceStatus} from 'interfaces';
import {FormProvider, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {yupResolver} from '@hookform/resolvers/yup';

import {DialogContainer} from '../Components/DialogContainer';
import {ProgressHeader} from '../Components/ProgressHeader';
import {QuestionInput} from '../Components/QuestionInput';
import {questionnaireValidationSchema} from '../validation';
import {
  generateIndividualQuestion,
  generateWellniteArtImage,
  generateWellniteArtQuestionnaire,
} from '../wellniteArtActions';
import {
  selectWellniteArtId,
  selectWellniteArtQuestionnaire,
  selectWellniteArtStatus,
} from '../wellniteArtSelectors';

import {ShareWellniteArt} from './ShareWellniteArt';
import {WellniteArtPreview} from './WellniteArtPreview';

interface Props {
  onComplete: VoidFunction;
  onClose: VoidFunction;
  appointmentId: string;
}

const shareStep = 4;
const totalSteps = 4;

export function SessionEnded({
  onClose,
  appointmentId,
}: Props): React.ReactElement {
  const {t} = useTranslation();
  const sliceStatus = useSelector(selectWellniteArtStatus);
  const questionnaire = useSelector(selectWellniteArtQuestionnaire);
  const wellniteArtId = useSelector(selectWellniteArtId);
  const [stepIndex, setStep] = useState<number>(0);
  const [regenerating, setRegenerating] = useState<boolean>(false);
  const [generatingArt, setGeneratingArt] = useState<boolean>(false);
  const [artGenerated, setArtGenerated] = useState<boolean>(false);

  const form = useForm({
    resolver: yupResolver(questionnaireValidationSchema),
    reValidateMode: 'onChange',
    defaultValues: {
      question1: '',
      question2: '',
      question3: '',
    },
  });

  useEffect(() => {
    dispatch(
      generateWellniteArtQuestionnaire({
        appointmentId,
        onSuccess: () => {},
      }),
    );
  }, []);

  const onGenerateNew = (questionId: string) => {
    setRegenerating(true);
    dispatch(
      generateIndividualQuestion({
        questionId,
        onSuccess: () => {
          setRegenerating(false);
        },
      }),
    );
  };

  const getAnswer = (index: number) => {
    // @ts-ignore
    return form.getValues('question' + (index + 1)) ?? '';
  };

  const isValidAnswer = (index: number) => {
    return getAnswer(index).length > 0;
  };

  const handleNext = () => {
    if (stepIndex === questionnaire.length - 1) {
      setGeneratingArt(true);
      dispatch(
        generateWellniteArtImage({
          magicArtId: wellniteArtId!,
          questionnaires: questionnaire.map((question, index) => ({
            questionId: question._id,
            answer: getAnswer(index),
          })),
          onSuccess: () => {
            setArtGenerated(true);
            setGeneratingArt(false);
            setStep(questionnaire.length);
          },
        }),
      );
    } else if (stepIndex < shareStep) {
      setStep(step => step + 1);
    }
  };

  const switchQuestion = (index: number) => {
    const lastValidIndex =
      questionnaire.findIndex((_, i) => isValidAnswer(i)) ?? 0;
    if (lastValidIndex >= index) {
      setStep(index);
    }
  };

  const handleClose = () => {
    if (stepIndex === shareStep || artGenerated || generatingArt) {
      onClose();
    } else {
      setGeneratingArt(true);
      dispatch(
        generateWellniteArtImage({
          magicArtId: wellniteArtId!,
          questionnaires: questionnaire.map((question, index) => ({
            questionId: question._id,
            answer: getAnswer(index)
              ? getAnswer(index)
              : question.placeholderAnswer,
          })),
          onSuccess: () => {
            setArtGenerated(true);
            setGeneratingArt(false);
            setStep(shareStep);
          },
        }),
      );
    }
  };

  const buttonEnabled = isValidAnswer(stepIndex) || stepIndex >= 3;

  if (sliceStatus === SliceStatus.rejected) {
    onClose();
  }

  return (
    <DialogContainer>
      <div className="text-center flex flex-col justify-start items-center space-y-2 self-center h-full w-full flex-grow flex-col">
        <ProgressHeader
          stepsCount={totalSteps}
          currentStep={stepIndex}
          onBackClick={() => setStep(stepIndex - 1)}
          onStepClick={switchQuestion}
          onCloseClick={() => handleClose()}
        />
        {stepIndex <= 2 ? (
          <>
            <div className="text-xl font-light text-black text-left xs:text-center w-full px-4 pt-4">
              {t('wellniteArt.sessionEnded')}
            </div>
            <div className="font-bold text-[40px] text-black max-w-[600px] font-montserrat text-left xs:text-center px-4">
              {t('wellniteArt.personalizeExperience')}
            </div>
          </>
        ) : null}
        {sliceStatus === SliceStatus.resolved ? (
          <div className="pb-6 md:pt-6 h-full flex flex-col flex-grow w-full items-center">
            {stepIndex < questionnaire.length ? (
              <FormProvider {...form}>
                {questionnaire?.map((question, index) => (
                  <div
                    key={index}
                    className={classNames(
                      'flex flex-row space-x-2 text-md md:text-lg text-gray-800 w-full',
                      {
                        hidden: index !== stepIndex,
                      },
                    )}
                  >
                    <article className="mb-5 pt-8 max-w-[600px] w-full m-auto relative px-3">
                      <QuestionInput
                        name={'question' + (index + 1)}
                        textareaClassName="text-sm h-25 md:h-25"
                        focused={index === stepIndex}
                        label={question.question}
                        placeholder={question.placeholderAnswer}
                        rows={4}
                      >
                        <button
                          className="text-primary text-sm p-1 absolute bg-gray-200 rounded-lg flex items-center justify-center"
                          style={{
                            right: 15,
                            bottom: 15,
                          }}
                          disabled={regenerating}
                          onClick={() => onGenerateNew(question._id)}
                        >
                          <RefreshIcon
                            strokeColor="text-black"
                            rotating={regenerating}
                          />
                        </button>
                      </QuestionInput>
                    </article>
                  </div>
                ))}
              </FormProvider>
            ) : stepIndex === 3 ? (
              <WellniteArtPreview onNext={handleNext} />
            ) : (
              <ShareWellniteArt />
            )}

            {stepIndex < questionnaire.length ? (
              <div className="pt-3 h-full content-end flex-grow w-full px-3">
                <button
                  className="h-12 px-4 sm:px-8 flex items-center py-2.5 rounded-full text-base text-white mx-auto bg-primary justify-center bg-opacity-100 rounded-full disabled:opacity-50 disabled:bg-gray-500 cursor-pointer xs:min-w-[150px] min-w-[100%]"
                  disabled={!buttonEnabled}
                  onClick={handleNext}
                >
                  <p className="pr-1">{t('wellniteArt.nextQuestion')}</p>
                </button>
              </div>
            ) : null}
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center w-full">
            {stepIndex == questionnaire.length - 1 || generatingArt ? (
              <div>
                <TimerProgressBar
                  total={100}
                  duration={25000}
                  complete={false}
                  classes="my-4"
                  border="rounded-lg"
                ></TimerProgressBar>
                <div className="text-center text-black text-lg">
                  {t('wellniteArt.generatingArt')}
                </div>
              </div>
            ) : (
              <LoadingSpinner />
            )}
          </div>
        )}
      </div>
    </DialogContainer>
  );
}
