import React, {useState} from 'react';

import {SvgIcon} from './SvgIcon';

export const UserGroupIconV2: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-[#747474]',
  strokeWidth = 1.5,
  fillColor = 'none',
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19 20"
    className={`stroke-current ${strokeColor}`}
  >
    <circle
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      cx="4.875"
      cy="9.5"
      r="2.25"
    />
    <circle
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      cx="11.875"
      cy="4.5"
      r="3.25"
    />
    <path
      d="M6.40099 12.2631C6.14797 12.7373 5.90481 13.209 5.71877 13.6001C5.32142 13.3771 4.86306 13.25 4.375 13.25C2.85622 13.25 1.625 14.4812 1.625 16V19.5H0.125V16C0.125 13.6528 2.02779 11.75 4.375 11.75C5.10858 11.75 5.79875 11.9359 6.40099 12.2631Z"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.875 19.5V14.5C17.875 11.1863 15.1887 8.5 11.875 8.5V8.5C8.56129 8.5 5.875 11.1863 5.875 14.5V19.5"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const AvailabilityIconV2: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-[#747474]',
  strokeWidth = 1.5,
  fillColor = 'none',
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    className={`stroke-current ${strokeColor}`}
  >
    <circle
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      cx="10"
      cy="10"
      r="9.25"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M10 5V10L13.5 13"
    />
  </svg>
);

export const CollaborationIconV2: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-[#747474]',
  strokeWidth = 0.2,
}) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    className={`stroke-current fill-current ${strokeColor}`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12.5 4C12.5 5.38071 11.3807 6.5 10 6.5C8.61929 6.5 7.5 5.38071 7.5 4C7.5 2.61929 8.61929 1.5 10 1.5C11.3807 1.5 12.5 2.61929 12.5 4ZM13.6562 5.62493C13.0333 7.02432 11.6306 8 10 8C8.36941 8 6.9667 7.02432 6.34383 5.62493C4.62716 6.7949 3.5 8.76578 3.5 10.9999C3.5 11.3476 3.5273 11.6889 3.57987 12.0218C3.71794 12.0074 3.85811 12 4 12C6.20914 12 8 13.7909 8 16C8 16.393 7.94332 16.7728 7.83769 17.1316C8.51414 17.3701 9.24191 17.4999 10 17.4999C10.7581 17.4999 11.4859 17.3701 12.1623 17.1316C12.0567 16.7728 12 16.393 12 16C12 13.7909 13.7909 12 16 12C16.1419 12 16.2821 12.0074 16.4201 12.0218C16.4727 11.6889 16.5 11.3476 16.5 10.9999C16.5 8.76578 15.3728 6.7949 13.6562 5.62493ZM13.9994 4.06984C16.3909 5.45299 18 8.03855 18 10.9999C18 11.4991 17.9543 11.9876 17.8668 12.4614C19.1355 13.1321 20 14.4651 20 16C20 18.2091 18.2091 20 16 20C14.7252 20 13.5896 19.4036 12.8571 18.4746C11.9698 18.814 11.0066 18.9999 10 18.9999C8.99337 18.9999 8.03017 18.814 7.14285 18.4746C6.41038 19.4036 5.27483 20 4 20C1.79086 20 0 18.2091 0 16C0 14.4651 0.864522 13.1321 2.13319 12.4614C2.04572 11.9876 2 11.4991 2 10.9999C2 8.03855 3.60906 5.45299 6.0006 4.06984C6.0002 4.04661 6 4.02333 6 4C6 1.79086 7.79086 0 10 0C12.2091 0 14 1.79086 14 4C14 4.02333 13.9998 4.04661 13.9994 4.06984ZM6.5 16C6.5 17.3807 5.38071 18.5 4 18.5C2.61929 18.5 1.5 17.3807 1.5 16C1.5 14.6193 2.61929 13.5 4 13.5C5.38071 13.5 6.5 14.6193 6.5 16ZM16 18.5C17.3807 18.5 18.5 17.3807 18.5 16C18.5 14.6193 17.3807 13.5 16 13.5C14.6193 13.5 13.5 14.6193 13.5 16C13.5 17.3807 14.6193 18.5 16 18.5Z"
    />
  </svg>
);

export const TrashIcon: SvgIcon = ({
  width = 23,
  height = 21,
  strokeColor = 'text-transparent',
  fillColor = 'fill-white',
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <svg
      width={width}
      height={height}
      stroke={strokeColor}
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 23 21"
      className={`fill-current ${strokeColor}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <path
        d="M4.25 4.75H18.75V17C18.75 18.7949 17.2949 20.25 15.5 20.25H7.5C5.70507 20.25 4.25 18.7949 4.25 17V4.75Z"
        stroke={isHovered ? '#315eff' : '#585858'}
      />
      <rect
        x="0.5"
        y="4"
        width="22"
        height="1.5"
        fill={isHovered ? '#315eff' : '#585858'}
      />
      <rect
        x="7.5"
        width="1.5"
        height="5"
        fill={isHovered ? '#315eff' : '#585858'}
      />
      <path d="M8.5 8H10V17H8.5V8Z" fill={isHovered ? '#315eff' : '#585858'} />
      <rect
        x="14"
        width="1.5"
        height="5"
        fill={isHovered ? '#315eff' : '#585858'}
      />
      <path d="M13 8H14.5V17H13V8Z" fill={isHovered ? '#315eff' : '#585858'} />
      <rect
        x="7.5"
        width="8"
        height="1.5"
        fill={isHovered ? '#315eff' : '#585858'}
      />
      <rect x="7.5" width="6" height="1" />
    </svg>
  );
};

export const PlusCircleV2: SvgIcon = ({
  width = 19,
  height = 19,
  strokeColor = 'text-[#2E62EC]',
  strokeWidth = 1.5,
  fillColor = '#2E62EC',
}) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19 18"
    className={`stroke-current ${strokeColor}`}
  >
    <circle
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      cx="9.5"
      cy="9"
      r="8.25"
    />
    <path
      fill={fillColor}
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={0.2}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M10.25 4H8.75V8.25H4.5V9.75H8.75V14H10.25V9.75H14.5V8.25H10.25V4Z"
    />
  </svg>
);

export const CaretDownIconV2: SvgIcon = ({
  width = 12,
  height = 5,
  strokeColor = 'text-[#747474]',
  strokeWidth = 0.05,
  fillColor = '#747474',
}) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12 5"
    className={`stroke-current ${strokeColor}`}
  >
    <path
      fill={fillColor}
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6 5L11.5 0H0.5L6 5Z"
    />
  </svg>
);

export const ProgressIcon: SvgIcon = ({
  width = 22,
  height = 19,
  strokeColor = 'text-[#747474]',
  strokeWidth = 1.5,
  classes = '',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`stroke-current ${strokeColor} ${classes}`}
  >
    <path d="M0 19H22V20.5H0V19Z" fill="#747474" />
    <path d="M1 14.5L8 7.5L11 10.5L20 1.5" strokeWidth={strokeWidth} />
    <path d="M16 1.5H20V5.5" strokeWidth={strokeWidth} />
  </svg>
);

export const CollaborationHeartIcon: SvgIcon = ({
  width = 26,
  height = 26,
  fillColor = 'none',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 26 27"
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="26" height="26" rx="13" fill="#3B61E4" />
    <path
      d="M4.74712 16.4051C2.67764 14.3356 2.67764 10.9803 4.74712 8.91084C6.8166 6.84136 10.1719 6.84136 12.2414 8.91084L20.1519 16.8214L14.6007 22.3727C13.5276 23.4458 11.7878 23.4458 10.7148 22.3727L4.74712 16.4051Z"
      fill="#F7CD6C"
    />
    <path
      d="M5.16406 16.8214L13.0747 8.91082C15.1441 6.84135 18.4994 6.84135 20.5689 8.91082C22.6384 10.9803 22.6384 14.3356 20.5689 16.4051L14.1789 22.7951C13.3391 23.6349 11.9775 23.6349 11.1377 22.7951L5.16406 16.8214Z"
      fill="#DF87B1"
    />
    <g filter="url(#filter0_d_9066_5369)">
      <circle
        cx="8.54322"
        cy="12.658"
        r="5.33386"
        transform="rotate(-45 8.54322 12.658)"
        fill="#F19E38"
      />
      <circle
        cx="8.54322"
        cy="12.658"
        r="4.33386"
        transform="rotate(-45 8.54322 12.658)"
        stroke="#F5BE4B"
        strokeWidth="2"
      />
    </g>
    <rect
      x="7.26562"
      y="10.3986"
      width="1.93959"
      height="4.84897"
      fill="#F7D072"
    />
    <defs>
      <filter
        id="filter0_d_9066_5369"
        x="1.70898"
        y="5.8241"
        width="13.668"
        height="13.6677"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="1.5"
          operator="dilate"
          in="SourceAlpha"
          result="effect1_dropShadow_9066_5369"
        />
        <feOffset />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.231373 0 0 0 0 0.380392 0 0 0 0 0.894118 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_9066_5369"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_9066_5369"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
