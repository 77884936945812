import React, {FC, useEffect, useState} from 'react';
import {ProgressBar} from 'components/index';

interface TimerProgressBarProps {
  total: number;
  duration: number;
  complete: boolean;
  backgroundColor?: string;
  classes?: string;
  fillColor?: string;
  height?: string;
  width?: string;
  border?: string;
  shadow?: string;
  progressClasses?: string;
}

export const TimerProgressBar: FC<TimerProgressBarProps> = ({
  total,
  complete,
  backgroundColor,
  fillColor,
  height,
  duration,
  width,
  progressClasses,
  border,
  shadow,
  classes,
}) => {
  const [fill, setFill] = useState(0);

  useEffect(() => {
    if (complete) {
      setFill(total);
      return;
    }

    const intervalTime = duration / total;

    const interval = setInterval(() => {
      setFill(prevFill => {
        const progress = prevFill / total;
        const increment = (1 - progress ** 3) * (total / 55);
        const newFill = prevFill + increment;
        return newFill >= total * 0.99 ? total * 0.99 : newFill;
      });
    }, intervalTime);

    return () => clearInterval(interval);
  }, [complete, total]);

  const percentage = Math.floor((fill / total) * 100);

  return (
    <div className={`${width} ${classes}`}>
      <ProgressBar
        fill={fill}
        total={total}
        backgroundColor={backgroundColor}
        fillColor={fillColor}
        height={height}
        width={width}
        border={border}
        shadow={shadow}
      />
      <div className={`${progressClasses ?? 'mt-2 text-center'}`}>
        {percentage}%
      </div>
    </div>
  );
};
