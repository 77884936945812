import {useEffect, useRef} from 'react';
import {appointmentActions} from 'features/Appointment';
import {notificationActions} from 'features/Notification';
import {userActions} from 'features/User';
import {
  Appointment,
  CurrentUser,
  MemberProfile,
  PrescriberProfile,
  SocketEvents,
  TherapistProfile,
  TNotification,
} from 'interfaces';
import {useDispatch} from 'react-redux';
import {getBaseUrl} from 'services/api';
import socketIOClient from 'socket.io-client';
import {isProvider} from 'utils';

/**
 *
 * @param user - logged in user: CurrentUser
 */
export function useSocket(user: CurrentUser) {
  const dispatch = useDispatch();
  const isSocketConnected = useRef<boolean>(false);

  const userId =
    (user as MemberProfile)?.patientId ||
    (user as PrescriberProfile)?.prescriberId ||
    (user as TherapistProfile)?.therapistId;

  const _isProvider = isProvider(user);

  useEffect(() => {
    if (!userId || isSocketConnected.current) return;

    const socket = socketIOClient(getBaseUrl(), {
      withCredentials: true,
      secure: true,
      reconnection: true,
      reconnectionAttempts: 5, // Number of reconnection attempts before giving up
      reconnectionDelay: 1000, // Delay between reconnection attempts (in milliseconds)
      reconnectionDelayMax: 5000, // Maximum delay between reconnection attempts (in milliseconds)
      timeout: 20000, // Connection timeout before failing (in milliseconds)
    });

    isSocketConnected.current = true;

    socket.on('connect', () => {
      console.log('ws: connected');

      socket.emit(SocketEvents.JOIN, userId);
    });

    socket.on(SocketEvents.NEW_NOTIFICATION, (data: TNotification) => {
      if (
        data.notificationType === 'session_rate_change' &&
        data.notification?.data
      ) {
        dispatch(
          userActions.updateDpProvidersSessionRateForMember({
            sessionRate: data.notification.data.newRate!,
            providerId: data.notification.data.providerId!,
            providerRole: data.notification.data.providerRole!,
          }),
        );
      }

      dispatch(
        notificationActions.notificationReceived({
          notification: data,
          notificationType: data.notificationType,
        }),
      );
    });

    socket.on(SocketEvents.SYNC_APPOINTMENT, (data: Appointment) => {
      if (_isProvider && data.canceled) {
        dispatch(appointmentActions.removeAppointment(data));
      } else {
        dispatch(appointmentActions.addAppointment(data));
      }
    });

    return () => {
      socket.disconnect();
      isSocketConnected.current = false;
    };
  }, [userId]);
}
