import {BROWSER_ENUM, EndPoints, QueryParameters} from 'interfaces';
import {isPlainObject} from 'is-plain-object';
import publicIp from 'public-ip';
import {Observable} from 'rxjs';
import {UAParser} from 'ua-parser-js';

export const joinArrStrings = (arr: string[]): string => {
  if (Array.isArray(arr)) {
    return arr.length === 0 ? '' : arr.length === 1 ? arr[0] : arr.join(', ');
  }
  return '';
};

export const getFirstLetterOfNames = (fullName: string) => {
  const names = fullName.split(' ', 2);
  let text = '';
  for (const name of names) {
    text += name[0];
  }
  return text;
};

export const replaceAllCharacters = (
  target: string,
  search: string,
  replace: string,
): string => target.split(search).join(replace);

export const range = (start: number, end: number): number[] =>
  Array.from({length: end - start}, (_v, k) => k + start);

export const entries = Object.entries as <T>(obj: T) => [keyof T, T[keyof T]][];

export const emptyishProperties = (obj: object) =>
  Object.entries(obj)
    .filter(
      ([, val]) =>
        val === null || val === undefined || val === '' || val.length === 0,
    )
    .map(([key]) => key);

export const titleCaseName = (str: string): string => {
  const words = str.toLowerCase().split(' ');
  const result = words.map(val =>
    val.replace(val.charAt(0), val.charAt(0).toUpperCase()),
  );
  return result.join(' ');
};

export const unwrapAPIError = (error: any): string => {
  let errorValue = error?.data || error?.statusText;
  if (!error?.data && error?.message) {
    errorValue = error.message;
  }
  if (errorValue?.message) {
    errorValue = errorValue.message;
  }
  if (errorValue?.[0] && errorValue?.[0]?.message) {
    errorValue = errorValue[0].message;
  }

  return typeof errorValue === 'string' ? errorValue : 'An error occurred.';
};

export const createObservableFromFirebase = <P = any, R = any, O = any>(
  promise: Promise<P>,
  doneValue?: R,
): Observable<O> =>
  new Observable<O>((observer: any) => {
    promise
      .then(res => {
        observer.next(doneValue ? doneValue : res);
        observer.complete();
      })
      .catch(err => {
        observer.error(err);
      });
  });

export function findLast<T>(
  items: T[],
  predicate: (item: T) => boolean,
):
  | {
      item: T;
      index: number;
    }
  | {
      item: undefined;
      index: number;
    } {
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    if (predicate(item)) {
      return {item, index: items.length - 1 - i};
    }
  }
  return {item: undefined, index: -1};
}

const parser = new UAParser();
export const isMobile = (() => {
  if (
    typeof navigator === 'undefined' ||
    typeof navigator.userAgent !== 'string'
  ) {
    return false;
  }
  return /Mobile/.test(navigator.userAgent);
})();

export const deviceType = parser.getDevice().type;

const osName = parser.getOS().name;
export const isIos = osName?.toLowerCase() === 'ios';
export const isAndroid = osName?.toLowerCase() === 'android';

export const getMemberIP = async (): Promise<string> => {
  try {
    return await publicIp.v4();
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

export const isInPwa = () => {
  return (
    ['fullscreen', 'standalone', 'minimal-ui'].some(
      displayMode =>
        window.matchMedia(`(display-mode: ${displayMode})`).matches,
    ) || window.navigator.standalone === true
  );
};

export const isPwaNotSupported = (() =>
  !isMobile &&
  !['chrome', 'edge'].includes(
    parser?.getBrowser()?.name?.toLowerCase() || '',
  ))();

export const isNotificationSupported = (() =>
  'Notification' in window && 'serviceWorker' in navigator)();

export function removeUndefineds<T>(obj: T): T {
  if (!isPlainObject(obj)) return obj;

  const target: {[name: string]: any} = {};
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const objKeys = Object.keys(obj);
  for (const key of objKeys) {
    const val = obj[key];
    if (typeof val !== 'undefined') {
      target[key] = removeUndefineds(val);
    }
  }

  return target as T;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toFormData = (values: {[key: string]: any}): FormData => {
  const formData = new FormData();
  Object.keys(values).forEach(key => {
    formData.append(key, values[key]);
  });
  return formData;
};

export const urlBase64ToUint8Array = (base64String: string) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

export const componentLoader = (
  lazyComponent: () => any,
  attemptsLeft: number = 3,
): Promise<any> => {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error: any) => {
        // let us retry after 1500 ms
        setTimeout(() => {
          if (attemptsLeft === 1) {
            reject(error);
            return;
          }
          componentLoader(lazyComponent, attemptsLeft - 1).then(
            resolve,
            reject,
          );
        }, 300);
      });
  });
};

export const copyToClipboard = async (textToCopy: string) => {
  try {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      await navigator.clipboard.writeText(textToCopy);
    } else {
      const textField = document.createElement('textarea');
      textField.innerText = textToCopy;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand('copy');
      textField.remove();
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
};

export const formatTime = (seconds: number) => {
  if (isNaN(seconds)) return '00:00';

  const date = new Date(seconds * 1000);

  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = date.getUTCSeconds().toString().padStart(2, '0');

  if (hh) {
    return `${hh}:${mm.toString().padStart(2, '0')}:${ss}`;
  }
  return `${mm}:${ss}`;
};

export const getInsuranceClassNames = (insurance: string) => {
  const insur = insurance.toLowerCase().trim();
  if (insur.includes('optum')) {
    return 'text-black bg-red-200';
  } else if (insur.includes('oscar')) {
    return 'text-white bg-orange-500';
  } else if (insur.includes('evernorth')) {
    return 'text-white bg-violet-700';
  } else if (insur.includes('cigna')) {
    return 'text-white bg-blue-500';
  } else if (insur.includes('beacon')) {
    return 'text-white bg-sky-800';
  } else if (insur.includes('aetna')) {
    return 'text-black bg-slate-400';
  } else if (insur.includes('florida blue')) {
    return 'text-white bg-brick-700';
  } else if (insur.includes('humana')) {
    return 'text-black bg-zinc-400';
  } else if (insur.includes('united')) {
    return 'text-white bg-green-300';
  } else if (insur.includes('bcbs')) {
    return 'text-black bg-sky-300';
  } else if (insur.includes('blue shield')) {
    return 'text-black bg-blue-300';
  } else if (insur.includes('magellan')) {
    return 'text-black bg-red-300';
  } else if (insur.includes('geha')) {
    return 'text-black bg-pink-200';
  }

  return 'text-white bg-brick-700';
};

function isBrave() {
  if (window.navigator?.brave?.isBrave?.name == 'isBrave') {
    return true;
  } else {
    return false;
  }
}

const testUserAgent = (regexp: RegExp): boolean =>
  regexp.test(window?.navigator?.userAgent);

function detectBrowser(): BROWSER_ENUM {
  switch (true) {
    case isBrave():
      return BROWSER_ENUM.BRAVE;
    case testUserAgent(/edg/i):
      return BROWSER_ENUM.EDGE;
    case testUserAgent(/trident/i):
      return BROWSER_ENUM.INTERNET_EXPLORER;
    case testUserAgent(/firefox|fxios/i):
      return BROWSER_ENUM.FIRE_FOX;
    case testUserAgent(/opr\//i):
      return BROWSER_ENUM.OPERA;
    case testUserAgent(/ucbrowser/i):
      return BROWSER_ENUM.UC_BROWSER;
    case testUserAgent(/samsungbrowser/i):
      return BROWSER_ENUM.SAMSUNG_BROWSER;
    case testUserAgent(/chrome|chromium|crios/i):
      return BROWSER_ENUM.CHROME;
    case testUserAgent(/safari/i):
      return BROWSER_ENUM.SAFARI;
    default:
      return BROWSER_ENUM.UNKNOWN;
  }
}

export const objectToQueryParams = (
  obj: Record<string, string | number | boolean | null | undefined>,
) => {
  const queryParams = [];
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      // Skip undefined or null values
      if (value !== undefined && value !== null) {
        queryParams.push(
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
        );
      }
    }
  }
  return queryParams.join('&');
};

export function snakeToCamelCase(str: string) {
  return str.replace(/_([a-z])/g, function (_match, char) {
    return char.toUpperCase();
  });
}

/**
 * @description get `Browser` name
 */
export const BROWSER: BROWSER_ENUM = detectBrowser();

export function attachQueryParameters(
  url: string | EndPoints,
  parameters: QueryParameters,
) {
  if (parameters && Object.keys(parameters).length > 0) {
    const separator = url.includes('?') ? '&' : '?';

    const queryString = Object.entries(parameters)
      .filter(([, value]) => value !== undefined && value !== '')
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value!)}`,
      )
      .join('&');

    url += separator + queryString;
  }

  return url;
}

export function capitalizeTimezone(timezone: string) {
  // Split the input string by '/'
  const parts = timezone.split('/');

  // Capitalize the first letter of each part
  const capitalizedParts = parts.map(part => {
    return part
      .split('_')
      .map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join('_');
  });

  // Join the capitalized parts with '/'
  const result = capitalizedParts.join('/');

  return result;
}
/**
 *
 * @param id string (i.e. id of the element to scroll to)
 */
export const scrollToTop = (id: string) => {
  const target = document.getElementById(id);
  if (target) {
    target.scrollTo({top: 0});
  }
};

export const calculateStartDate = (
  selectedDayValue: string,
  startDate = new Date(),
) => {
  if (!selectedDayValue) {
    return null;
  }

  const selectedDayNumber = {M: 1, T: 2, W: 3, Th: 4, F: 5, S: 6, Su: 0}[
    selectedDayValue
  ];
  const daysDifference = (selectedDayNumber! - startDate.getDay() + 7) % 7;
  const isStartDateToday = startDate.getDate() === new Date().getDate();
  if (isStartDateToday && daysDifference === 0) {
    // If the selected day is the same as the current day, move to the next week
    startDate.setDate(startDate.getDate() + 7);
  } else {
    startDate.setDate(startDate.getDate() + daysDifference); // Move to the calculated day
  }
  return startDate.toLocaleDateString('en-CA', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
};
export const isOldInsuranceMemberWithOldInsuranceList = (
  companyId?: string,
) => {
  /*This checks if their company field value contains numeric characters or if the value is BONONEDI 
    indicating that they got onboarded with the old insurance company list, before Airtable integration was done.
  */
  return (companyId && /\d/.test(companyId)) || companyId === 'BONONEDI';
};

export function capitalizeFirstLetter(str: string) {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}
export const generateStrongPassword = (id: string): string => {
  const predefinedCharacters: string =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  const trimmedId: string = id.slice(0, 6);

  const combinedCharacters: string = trimmedId + predefinedCharacters;

  let password: string = '';
  while (password.length < 7) {
    const index: number = password.length % combinedCharacters.length;
    password += combinedCharacters[index];
  }

  return password;
};

export const removeEmptyStrings = <T extends Record<string, any>>(
  obj: T,
): T => {
  const result: Partial<T> = {};
  for (const key in obj) {
    if (typeof obj[key] === 'string' && obj[key].trim() !== '') {
      result[key] = obj[key];
    }
  }
  return result as T;
};

export const calculateAge = (dob: number | string | Date): number => {
  const dobDate = new Date(dob); // Convert DOB string to a Date object
  const now = new Date(); // Current date and time

  let age = now.getUTCFullYear() - dobDate.getUTCFullYear(); // Calculate the year difference
  const monthDifference = now.getUTCMonth() - dobDate.getUTCMonth();
  const dayDifference = now.getUTCDate() - dobDate.getUTCDate();

  // Adjust age if the birthday hasn't occurred yet this year
  if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
    age--;
  }

  return age;
};

export function calculatePercentage(percent: number, value: number) {
  return (value * percent) / 100;
}

export const toCamelCase = (str: string) => {
  return str
    .replace(/\s(\w)/g, function (_, group1) {
      return group1.toUpperCase();
    })
    .replace(/^\w/, function (match) {
      return match.toLowerCase();
    })
    .replace(/\s/g, '');
};
