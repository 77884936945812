import React, {FC, useEffect, useState} from 'react';
import {Button} from 'components';
import {providerActions} from 'features/Provider';
import {UserRoles} from 'interfaces';
import {useDispatch} from 'react-redux';

import Modal from '.';

interface StoryLaneVideoModalProps {
  isOpen: boolean;
  onClose: () => void;
  onComplete: () => void;
  userRole: UserRoles;
}

const StoryLaneVideoModal: FC<StoryLaneVideoModalProps> = ({
  isOpen,
  onClose,
  onComplete,
  userRole,
}) => {
  const dispatch = useDispatch();
  const [videoStarted, setVideoStarted] = useState(false);
  const [isCompleting, setIsCompleting] = useState(false);
  const storylaneUrl = 'https://app.storylane.io/share/yp0hmvvryuze';

  useEffect(() => {
    if (isOpen && !videoStarted) {
      setVideoStarted(true);
      dispatch(providerActions.changeOnboardingVideoStatus(userRole));
    }
  }, [isOpen, videoStarted, dispatch, userRole]);

  const handleWatchedButtonClick = () => {
    if (isCompleting) return;

    setIsCompleting(true);

    dispatch(providerActions.completeOnboarding(userRole));

    setTimeout(() => {
      onComplete();
      onClose();
    }, 1000);
  };

  return (
    <Modal
      messageType="none"
      isOpen={isOpen}
      showCloseIcon={true}
      buttonFn={onClose}
      containerClasses="max-w-6xl w-full h-full"
      classes="relative w-full h-full overflow-hidden"
      title="Provider Onboarding"
      modalContainerClasses="flex items-center justify-center min-h-screen"
    >
      <div className="w-full h-full">
        <iframe
          src={`${storylaneUrl}?autoplay=true`}
          title="Wellnite Provider Onboarding"
          className="w-full h-[600px] md:h-[70vh]"
          allow="autoplay; fullscreen"
          allowFullScreen
          frameBorder="0"
        ></iframe>
      </div>
      <div className="text-center mt-6 pb-4">
        {isCompleting ? (
          <div className="flex flex-col items-center justify-center">
            <p className="text-green-600 font-semibold text-lg mb-2">
              Great! Redirecting to your dashboard...
            </p>
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
          </div>
        ) : (
          <div className="flex flex-col items-center">
            <p className="text-center text-sm text-gray-600 mb-4">
              Please watch the onboarding video to learn about the platform.
            </p>
            <Button
              btnType="primary"
              onClick={handleWatchedButtonClick}
              className="py-3 px-8 text-base font-medium"
            >
              I've watched the onboarding video
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default StoryLaneVideoModal;
