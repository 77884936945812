import React from 'react';

import {SvgIcon} from './SvgIcon';

export const PlusIcon: SvgIcon = ({
  width = 13,
  height = 12,
  strokeColor = '#2E62EC',
  fillColor = 'none',
  classes = '',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 13 12"
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    className={`fill-current ${strokeColor} ${classes}`}
  >
    <path d="M5.5 0H7.5V12H5.5V0Z" />
    <path d="M0.5 5H12.5V7H0.5V5Z" />
  </svg>
);

export const RemoveNotificationIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  strokeWidth = 1.2,
  fillColor = 'none',
  classes = '',
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    className={`stroke-current ${strokeColor} ${classes}`}
  >
    <path
      d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm5-11H5v2h10V9z"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const UploadIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  strokeWidth = 1.2,
  fillColor = 'none',
  classes = '',
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    className={`stroke-current ${strokeColor} ${classes}`}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={strokeWidth}
      d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
    />
  </svg>
);

export const EditIcon: SvgIcon = ({
  width = 16,
  height = 16,
  strokeColor = 'text-black',
  fillColor = 'none',
  classes = '',
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    className={`stroke-current ${strokeColor} ${classes}`}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
  </svg>
);

export const InfoIcon: SvgIcon = ({
  width = 16,
  height = 16,
  strokeColor = 'text-[#b1b1b1]',
  fillColor = 'none',
  classes = '',
  strokeWidth = 1.5,
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    className={`${strokeColor} ${classes}`}
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.75"
      y="0.75"
      width="16.5"
      height="16.5"
      rx="8.25"
      stroke="currentColor"
      strokeWidth={strokeWidth}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 4.5C10 3.94772 9.55228 3.5 9 3.5C8.44772 3.5 8 3.94772 8 4.5C8 5.05228 8.44772 5.5 9 5.5C9.55228 5.5 10 5.05228 10 4.5ZM10 8C10 7.44772 9.55228 7 9 7C8.44772 7 8 7.44772 8 8V13.5C8 14.0523 8.44772 14.5 9 14.5C9.55228 14.5 10 14.0523 10 13.5V8Z"
      fill="currentColor"
    />
  </svg>
);

export const InfoIconTwo: SvgIcon = ({
  width = 24,
  height = 24,
  strokeColor = 'text-black',
  fillColor = 'none',
  classes = '',
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    strokeWidth="1.5"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    className={`fill-current ${strokeColor} ${classes}`}
  >
    <path
      d="M12 11.5v5M12 7.51l.01-.011M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"
      stroke="#000000"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
  </svg>
);

export const PlusIconV2: SvgIcon = ({
  width = 13,
  height = 12,
  strokeColor = '#2E62EC',
  fillColor = 'none',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 13 12"
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5.5 0H7.5V12H5.5V0Z" fill={strokeColor} />
    <path d="M0.5 5H12.5V7H0.5V5Z" fill={strokeColor} />
  </svg>
);

export const EditPenIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  strokeWidth = 1.5,
  fillColor = 'none',
  classes = '',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 19 20"
    fill={fillColor}
    className={`stroke-current ${strokeColor} ${classes}`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2.5 12L7.5 17" strokeWidth={strokeWidth} />
    <path
      d="M1.23016 17.119L1.94732 12.8161C1.98158 12.6105 2.07923 12.4208 2.2266 12.2734L12.5858 1.91421C13.3668 1.13317 14.6332 1.13316 15.4142 1.91421L17.5858 4.08579C18.3668 4.86684 18.3668 6.13316 17.5858 6.91421L7.2266 17.2734C7.07923 17.4208 6.88948 17.5184 6.6839 17.5527L2.38095 18.2698C1.70413 18.3826 1.11736 17.7959 1.23016 17.119Z"
      strokeWidth={strokeWidth}
    />
  </svg>
);
