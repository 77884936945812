/* import {AppThunk, AppDispatch} from 'app/store'
import {OnboardingService} from 'services/api' */
import {
  InsuranceNamesType,
  MemberProfile,
  PverifyInfoType,
  SocialOnboardingStatusEnum,
  SocialOnboardingType,
} from 'interfaces';

import {createAction} from '@reduxjs/toolkit';

export const saveSocialOnboardingData = createAction<{
  data: Partial<SocialOnboardingType>;
  onboardStage: number;
  socialOnboardingExtraFlow?: string;
  isUpdateInsurance?: boolean;
}>('saveSocialOnboardingData');
export const saveSocialOnboardingDataSuccess = createAction(
  'saveSocialOnboardingDataSuccess',
);
export const saveSocialOnboardingDataFailure = createAction(
  'saveSocialOnboardingDataFailure',
);

export const fetchInsuranceNames = createAction('fetchInsuranceNames');
export const fetchInsuranceNamesSuccess = createAction<InsuranceNamesType>(
  'fetchInsuranceNamesSuccess',
);
export const fetchInsuranceNamesFailure = createAction(
  'fetchInsuranceNamesFailure',
);

export const updatePverifyInfo =
  createAction<PverifyInfoType>('updatePverifyInfo');

export const changeSocialOnboardingStatus = createAction<{
  socialOnboardingStatus: SocialOnboardingStatusEnum;
  onboardStage?: number;
}>('changeSocialOnboardingStatus');

export const changeSocialOnboardingStatusSuccess = createAction<MemberProfile>(
  'changeSocialOnboardingStatusSuccess',
);

export const changeSocialOnboardingStatusFailure = createAction(
  'changeSocialOnboardingStatusFailure',
);
