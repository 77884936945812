import {
  FormSubmissionStatus,
  OnboardingVideoStatus,
  VerificationStatusEnum,
} from 'definitions/Onboarding/provider';

import {CometChat} from '@cometchat-pro/chat';

import {CountryOfResidenceType} from './Countries.types';
import {
  DigitalPracticeAccountTypes,
  ProviderLicenseCategory,
  ProviderRegistrationSource,
} from './Pages';
import {PaystackSubaccountDetails} from './Paystack.types';
import {DependencyStatusEnum} from './PverifyInfo.types';
import {StripePlanNames, StripePlanTypes} from './StripePlans.types';

import TextMessage = CometChat.TextMessage;
import MediaMessage = CometChat.MediaMessage;
import CustomMessage = CometChat.CustomMessage;
import User = CometChat.User;
import Group = CometChat.Group;

export enum UserRoles {
  admin = 'admin',
  mentalhealthcoach = 'mentalhealthcoach',
  therapist = 'therapist',
  prescriber = 'prescriber',
  member = 'patient',
}

export enum MinorConsentStatus {
  Signed = 'signed',
  Sent = 'sent',
  Pending = 'pending',
  OverAge = 'over age',
}

export type ProviderRole = UserRoles.therapist | UserRoles.prescriber;

export enum ChatFeatureSetting {
  active = 'active',
  inactive = 'inactive',
}

export enum UserAccountType {
  wellnite = 'wellnite',
  scale = 'scale',
}

export enum InsuranceCompanyType {
  none = 'none',
  public = 'public',
  commercial = 'commercial',
}

export enum SocialOnboardingStatusEnum {
  ongoing = 'ongoing',
  rejected = 'rejected',
  accepted = 'accepted',
  complete = 'complete',
}

export type InsuranceDetails = {
  companyType: InsuranceCompanyType;
  companyName: string;
  employerName?: string;
  eapCode?: string;
  companyId: string;
  dependencyStatus: DependencyStatusEnum;
  memberId: string;
  onboardStage: number;
  onboardingStatus: SocialOnboardingStatusEnum;
  doctorFee: number;
  therapistFee: number;
  primaryInsuredGender: string;
  primaryInsuredLastName: string;
  primaryInsuredMiddleName: string;
  primaryInsuredFirstName: string;
  primaryInsuredDateOfBirth: {
    day: string;
    month: string;
    year: string;
  };
  dob: {
    day: string;
    month: string;
    year: string;
  };
  primaryInsuredStreetAddress: string;
  primaryInsuredZipcode: string;
  primaryInsuredCity: string;
  primaryInsuredState: string;
  isUpdateInsurance?: boolean;
};

export type BaseProfile = {
  fullName: string;
  role: UserRoles;
  email: string;
  image?: string;
  cometChatData?: {
    authToken: string;
    uid: string;
    chatState: string;
  };
  countryOfResidence: CountryOfResidenceType;
  preferredLanguage: string;
};

export type UnreadConversation = {
  conversationId: string;
  conversationType: string;
  lastMessage: TextMessage | MediaMessage | CustomMessage | any;
  conversationWith: User | Group;
  unreadMessageCount: number;
  tags: Array<string>;
};

export type UserWellbitWallet = {
  totalWellbits: number;
};

export type MedicalTeam = {
  fullName: string;
  email: string;
  image: string;
  bio: string;
  insurances: string[];
  education: string;
  states: string[];
  languages: string[];
  licenses: string[];
  conditions: string[];
};

export type ClonedVoice = {
  originalVoiceUrl: string;
  elevenLabsVoiceId: string;
  updatedAt: Date;
};

export type AddonSubscriptionTypes = 'Super Coach';

export type AddonSubscription = {
  stripeSubscriptionId: string;
  stripeSubscriptionItemId: string;
  name: AddonSubscriptionTypes;
  status: 'active' | 'not-subscribed' | 'unsubscribed';
  subscribedAt: Date;
  unsubscribedAt: Date;
};

export type MemberProfile = BaseProfile & {
  verified: boolean;
  prescriberDetails: MedicalTeam & {
    prescriberId: string;
    feePercentage: number;
    countryOfResidence: {
      code: string;
      name: string;
    };
    sessionRate: {
      digitalPractice: {
        min: number;
        max: number;
      };
    };
    defaultPaymentService?: string;
  };
  therapistDetails: MedicalTeam & {
    therapistId: string;
    feePercentage: number;
    countryOfResidence: {
      code: string;
      name: string;
    };
    sessionRate: {
      digitalPractice: {
        min: number;
        max: number;
      };
    };
    defaultPaymentService: string;
  };
  subscriptionStatus: string;
  addonSubscriptions?: AddonSubscription[];
  patientId: string;
  _id: string;
  phoneNumber: string;
  notifications: string[];
  date: number;
  dateOfBirth: Date;
  stateOfResidence: string;
  currentMedications: string[];
  allergies: string[];
  shippingAddress: {
    streetAddress: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
  };
  dueAppointments: number[];
  insuranceImageBack: string;
  insuranceImageFront: string;
  regStage: string;
  gender: string;
  pregnancyInfo: string[];
  drugAllergies: string;
  visADocForAnxDep: string;
  diagnosis: string[];
  existingConditions: string[];
  medicationsForAnxDep: string;
  purposeOfReg: string;
  anxiety: {
    nervous: string;
    controlWorrying: string;
    worryingTooMuch: string;
    troubleRelaxing: string;
    restlessness: string;
    easilyAnnoyed: string;
    fear: string;
  };
  depression: {
    littleInterest: string;
    feelingDown: string;
    sleepingTroubles: string;
    feelingTired: string;
    underOrOverEating: string;
    feelBadAboutSelf: string;
    troubleConcentrating: string;
    slowMovtOrFidgety: string;
    thoughtsOfHurtingSelf: string;
  };
  paidOrTrialAccess: {
    access: boolean;
    message: string;
  };
  likedMagicArts?: string[];
  suicidalThoughts: string;
  suicidalThoughtsInDetails: string;
  suicidalThoughtsOthers: string;
  suicidalThoughtsOthersInDetails: string;
  currentlySuicidal: string;
  currentlySuicidalInDetails: string;
  currentlySuicidalOthers: string;
  currentlySuicidalOthersInDetails: string;
  seizure: string;
  paymentPlan: StripePlanNames;
  __subPaymentPlan: StripePlanTypes;
  socialOnboardingExtraFlow: string;
  patientCards: {id: string; brand: string; last4: string}[] | string;
  paystackCards: {
    authCode: string;
    cardBin: string;
    last4: string;
    expMonth: string;
    expYear: string;
    cardType: string;
    bank: string;
    countryCode: string;
    cardBrand: string;
    accountName: string | null;
    signature: string;
    _id: string;
  }[];
  stripeCustomerCard: {brand: string; last4: string} | string;
  currentGenderIdentification: string;
  localPharmData: {
    pharmName: string;
    pharmAddress: string;
  };
  governmentID: string;
  referral?: {
    referralCode: string;
    referralCount: number;
    referralCoinsTogether: number;
  };
  feedbackCount?: {
    family: number;
    friends: number;
    colleagues: number;
  };
  isPhoneVerified?: boolean;
  whereFrom?: string;
  insuranceDetails?: InsuranceDetails;
  companyName?: string;
  jobTitle?: string;
  companyShouldPay?: string;
  intakeQClientIds?: {
    questionnaireName: string;
    clientId: string;
    intakeId: string;
  }[];
  accountType: UserAccountType;
  digitalPracticeAccountType?: DigitalPracticeAccountTypes;
  therapist_Id?: string;
  doctorId?: string;
  stripeCustomerCards?: string[];
  stripeCustomerId?: string;
  paypalCustomerId?: string;
  paypalDefaultCard?: string;
  paystackDefaultCard?: string;
  wellbitWallet?: UserWellbitWallet;
  hasCompletedTherapyIntake?: boolean;
  hasPartiallyCompletedIntake?: boolean;
  minorConsent?: {
    parentGuardianInformation: {
      parentGuardianFirstName: string;
      parentGuardianMiddleName: string;
      parentGuardianLastName: string;
      parentGuardianPreferredPronoun: string;
      parentGuardianPhoneNumber: string;
      parentGuardianEmail: string;
    };
    signedInformation: {
      signedParentFirstName: string;
      signedParentSurname: string;
      signedChildFirstName: string;
      signedChildSurname: string;
      signature: string;
    };
    step: number;
    status: MinorConsentStatus;
    showMinorConsentForm: boolean;
  };
  isNewIntakeMember?: boolean;
  activeMagicArts: string[];
  hasAgreedToSuperCoachDisclaimer?: boolean;
};

export type TherapistProfile = BaseProfile & {
  therapistId: string;
  deelContractId: string;
  acuity: {calendarId: string; timezone: string; timezoneOffset?: number};
  states: string[];
  notes: string[];
  patients: string[];
  stripeAccountId?: string;
  paystackSubaccountDetails?: PaystackSubaccountDetails;
  stripeConnectOnboardingComplete?: boolean;
  _id: string;
  [key: string]: any | number;
  accountType?: UserAccountType;
  providerLicenseCategory?: ProviderLicenseCategory;
  providerLicenseType?: string;
  referralCode?: string;
  source?: ProviderRegistrationSource;
  hourlyRate?: number;
  onboarding?: {
    formStatus?: {
      verification?: FormSubmissionStatus;
      profile?: FormSubmissionStatus;
      caqh?: FormSubmissionStatus;
      onboarding?: FormSubmissionStatus;
    };
    verificationFormDateSubmitted?: number;
    firstTime?: boolean;
    currentStep?: number;
    verificationStatus?: VerificationStatusEnum;
    onboardingStage?: number;
    onboardingVideoStatus?: OnboardingVideoStatus;
  };
  profileInfo?: {
    bio: string;
    education: string;
    languages: string[];
    conditions: string[];
    insurances: string[];
    licenses: string[];
    image: string;
    expertSummary?: string;
  };
  title: string;
  academicInitials: string;
  customAcademicInitials: string;
  useCompanyNameInsteadOfMine: boolean;
  voice?: ClonedVoice;
  companyName: string;
  address: {
    addressLine: string;
    addressLine2: string;
    city: string;
    state: string;
    zipCode: string;
  };

  wellbitWallet?: UserWellbitWallet;
  paypalMerchantId?: string;
  paypal?: {
    onboardingStatus: string;
  };
  feePercentage?: number;
  sessionRate: {
    digitalPractice: {
      min: number;
      max: number;
    };
  };
  defaultPaymentService?: string;
  hasShownMonthlyCompensationModal: boolean;
};

export type PrescriberProfile = BaseProfile & {
  prescriberId: string;
  deelContractId: string;
  acuity: {calendarId: string; timezone: string};
  states: string[];
  notes: string[];
  patients: string[];
  _id: string;
  stripeAccountId?: string;
  stripeConnectOnboardingComplete?: boolean;
  paystackSubaccountDetails?: PaystackSubaccountDetails;
  accountType?: UserAccountType;
  providerLicenseCategory?: ProviderLicenseCategory;
  providerLicenseType?: string;
  referralCode?: string;
  source?: ProviderRegistrationSource;
  [key: string]: any | number;
  onboarding?: {
    formStatus?: {
      verification?: FormSubmissionStatus;
      profile?: FormSubmissionStatus;
      caqh?: FormSubmissionStatus;
      onboarding?: FormSubmissionStatus;
    };
    verificationFormDateSubmitted?: number;
    currentStep?: number;
    firstTime?: boolean;
    verificationStatus?: VerificationStatusEnum;
    onboardingStage?: number;
    onboardingVideoStatus?: OnboardingVideoStatus;
  };
  wellbitWallet?: UserWellbitWallet;
  feePercentage?: number;
  sessionRate: {
    digitalPractice: {
      min: number;
      max: number;
    };
  };
  hasShownMonthlyCompensationModal: boolean;
};

export type ProviderProfile = TherapistProfile | PrescriberProfile;

export type CurrentUser =
  | MemberProfile
  | TherapistProfile
  | PrescriberProfile
  | null;
