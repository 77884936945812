import React, {useEffect, useMemo, useState} from 'react';
import {getCongratsModalState} from 'app/selectors';
import {congratsInviteAction, congratsModalClose} from 'app/uiReducer';
import {CloseIcon} from 'components';
import {WellbitsEarningsCategories} from 'interfaces';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import {Dialog} from '@headlessui/react';

import {HeartIconV2, RightArrowIcon} from './Utils/Icons';

type CollabWellbitsType = {
  bgColor: string;
  wellbits?: string | number;
  wellbitsType?: string;
  description: string;
  buttonText: string;
  buttonLink: string;
  possibleEarnings: boolean;
};

const getCollaborationWellbits = (t: Function): CollabWellbitsType[] => [
  {
    bgColor: 'bg-[#F7E3B8]',
    wellbits: '500',
    wellbitsType: WellbitsEarningsCategories.colleagueReferral,
    description: t('congratsCollabs.colleagueReferralDesc'),
    buttonText: t('congratsCollabs.colleagueReferralBtn'),
    buttonLink: '/',
    possibleEarnings: true,
  },
  {
    bgColor: 'bg-[#F4FEDE]',
    wellbits: '50',
    wellbitsType: WellbitsEarningsCategories.dpClient,
    description: t('congratsCollabs.dpClientDesc'),
    buttonText: t('congratsCollabs.dpClientBtn'),
    buttonLink: '/',
    possibleEarnings: false,
  },
  {
    bgColor: 'bg-[#F4FEDE]',
    wellbits: '10',
    wellbitsType: WellbitsEarningsCategories.memberClient,
    description: t('congratsCollabs.memberClientDesc'),
    buttonText: t('congratsCollabs.memberClientBtn'),
    buttonLink: '/',
    possibleEarnings: false,
  },
  {
    bgColor: 'bg-[#F3E9FF]',
    wellbits: '100',
    wellbitsType: WellbitsEarningsCategories.associateSupervision,
    description: t('congratsCollabs.associateSupervisionDesc'),
    buttonText: t('congratsCollabs.associateSupervisionBtn'),
    buttonLink: '/',
    possibleEarnings: true,
  },
  {
    bgColor: 'bg-[#EAEFFD]',
    wellbits: 'XY',
    wellbitsType: WellbitsEarningsCategories.coaching,
    description: t('congratsCollabs.coachingDesc'),
    buttonText: t('congratsCollabs.coachingBtn'),
    buttonLink: '/',
    possibleEarnings: false,
  },
];

export const CongratsCollaborationModal = () => {
  const dispatch = useDispatch();
  const {isOpen, wellbitsType} = useSelector(getCongratsModalState);
  const {t} = useTranslation();
  const collaborationWellbits = useMemo(() => getCollaborationWellbits(t), [t]);

  const [data, setData] = useState<CollabWellbitsType | null>(null);

  useEffect(() => {
    if (!wellbitsType) return;
    setData(
      collaborationWellbits.find(item => item.wellbitsType === wellbitsType) ??
        null,
    );
  }, [isOpen, wellbitsType, collaborationWellbits]);

  const handleClose = () => {
    setData(null);
    dispatch(congratsModalClose());
  };

  if (!data) return null;

  const handleInviteClick = () =>
    dispatch(
      congratsInviteAction(data.wellbitsType as WellbitsEarningsCategories),
    );

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      className={`fixed inset-0 flex items-center justify-center z-50 ${data.bgColor}`}
    >
      <Dialog.Panel className="mx-auto w-full h-full bg-gray-100 relative">
        <button
          onClick={handleClose}
          className="fixed top-5 right-5 lg:top-8 lg:right-10 z-999"
        >
          <CloseIcon
            strokeColor="text-black"
            strokeWidth={40}
            classes="w-8 h-8"
          />
        </button>
        <section className="w-full h-full flex flex-col items-center justify-center gap-[10rem] px-4 md:px-0 responsive-w">
          <div className="flex flex-col items-center justify-center gap-6 md:gap-8 px-6 text-center">
            {data.possibleEarnings ? null : (
              <h2 className="font-montserrat text-xl md:text-[2.5rem] md:leading-[3rem] font-bold text-center">
                {t('collaboration.congrats')}
              </h2>
            )}
            <div className="flex items-center justify-center w-20 h-20 bg-blue-600 rounded-full">
              <HeartIconV2 width={60} height={60} />
            </div>
            <p
              className="font-inter text-base md:text-2xl font-medium"
              dangerouslySetInnerHTML={{
                __html: data.description.replace(
                  /(\d+)\s+Wellbits/gi,
                  '<strong>$1 Wellbits</strong>',
                ),
              }}
            />
          </div>
          <button
            className="w-full md:w-auto flex items-baseline justify-center gap-2.5 px-5 py-3.5 rounded-3xl bg-black font-inter text-base font-medium leading-tight text-white border-black"
            onClick={handleInviteClick}
          >
            <RightArrowIcon />
            {data.buttonText}
          </button>
        </section>
      </Dialog.Panel>
    </Dialog>
  );
};
