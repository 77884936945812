import {userActions} from 'features/User';
import {
  Audio,
  AudiosPaginationType,
  CloneVoiceResponse,
  ConvertToAudioResult,
  EndPoints,
  GenerateAudioScriptResponse,
  GeneratedAudio,
  RegenerateAudioParagraphResult,
  RegenerateAudioTrackResult,
  ScriptParagraph,
  SliceStatus,
} from 'interfaces';

import {ActionReducerMapBuilder, createSlice} from '@reduxjs/toolkit';

import {
  cloneVoice,
  cloneVoiceFailure,
  cloneVoiceSuccess,
  convertToAudio,
  convertToAudioFailure,
  convertToAudioSuccess,
  deleteAudio,
  deleteAudioFailure,
  deleteAudioSuccess,
  generateAudioCoverImage,
  generateAudioCoverImageFailure,
  generateAudioCoverImageSuccess,
  generateAudioScript,
  generateAudioScriptFailure,
  generateAudioScriptSuccess,
  generateAudioSuggestions,
  generateAudioSuggestionsFailure,
  generateAudioSuggestionsSuccess,
  getAudios,
  getAudiosFailure,
  getAudiosSuccess,
  getJobStatus,
  getJobStatusFailure,
  getJobStatusSuccess,
  regenerateAudioParagraph,
  regenerateAudioParagraphFailure,
  regenerateAudioParagraphSuccess,
  regenerateAudioTrack,
  regenerateAudioTrackFailure,
  regenerateAudioTrackSuccess,
  regenerateCoverImage,
  regenerateCoverImageFailure,
  regenerateCoverImageSuccess,
  resetWellniteAudios,
  submitAudio,
  submitAudioFailure,
  submitAudioSuccess,
} from './wellniteAudiosActions';

export type WellniteAudiosSliceState = {
  status: SliceStatus;
  generatedWellniteAudios?: CloneVoiceResponse;

  audioGenerationJobId?: string;
  audioGenerationJobStatus?: `processing` | `completed` | `failed`;

  audioRegenerationJobId?: string;
  audioRegenerationJobStatus?: `processing` | `completed` | `failed`;

  paragraphRegenerationJobId?: string;
  paragraphRegenerationJobStatus?: `processing` | `completed` | `failed`;

  generatedAudio?: GeneratedAudio;
  generatedScript: ScriptParagraph[];
  generatedScriptResponse?: GenerateAudioScriptResponse;
  coverImageUrl?: string;
  isRegeneratingAudio: boolean;
  isRegeneratingParagraph: boolean;
  isGeneratingImage: boolean;
  isGeneratingAudio: boolean;
  isGeneratingScript: boolean;
  error: string;
  audios: {
    audios: Audio[];
    pagination: AudiosPaginationType;
  };
};

export const wellniteAudiosSliceInitialState: WellniteAudiosSliceState = {
  status: SliceStatus.idle,
  error: '',
  isRegeneratingParagraph: false,
  isRegeneratingAudio: false,
  isGeneratingImage: false,
  isGeneratingAudio: false,
  isGeneratingScript: false,
  generatedScript: [],
  audios: {
    audios: [],
    pagination: {
      currentPage: 1,
      hasNextPage: true,
      totalAudios: 0,
      nextPage: 1,
    },
  },
};

const wellniteAudiosSlice = createSlice({
  name: 'wellniteAudios',
  initialState: wellniteAudiosSliceInitialState as WellniteAudiosSliceState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<WellniteAudiosSliceState>) =>
    builder
      .addCase(userActions.setAsyncError, (state, action) => ({
        ...state,
        error:
          action.payload.filter === 'wellniteAudios'
            ? action.payload.message
            : state.error,
      }))
      .addCase(userActions.resetAsyncError, (state, action) => ({
        ...state,
        error: action.payload === 'wellniteAudios' ? '' : state.error,
      }))
      .addCase(cloneVoice, state => ({
        ...state,
        status: SliceStatus.pending,
        error: '',
      }))
      .addCase(cloneVoiceSuccess, (state, payload) => ({
        ...state,
        generatedWellniteAudios: payload.payload,
        status: SliceStatus.resolved,
        error: '',
      }))
      .addCase(getAudios, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(getAudiosSuccess, (state, action) => ({
        ...state,
        audios: {
          audios: [...state.audios.audios, ...action.payload.audios],
          pagination: action.payload.pagination,
        },
        status: SliceStatus.resolved,
      }))
      .addCase(getAudiosFailure, (state, action) => ({
        ...state,
        status: SliceStatus.rejected,
        error: action.payload,
      }))
      .addCase(generateAudioScript, state => ({
        ...state,
        isGeneratingScript: true,
        status: SliceStatus.pending,
        error: '',
      }))
      .addCase(generateAudioScriptSuccess, (state, {payload}) => ({
        ...state,
        isGeneratingScript: false,
        generatedScriptResponse: payload,
        generatedScript: payload.script,
        status: SliceStatus.resolved,
        error: '',
      }))
      .addCase(generateAudioScriptFailure, state => ({
        ...state,
        isGeneratingScript: false,
        status: SliceStatus.rejected,
        error: '',
      }))
      .addCase(convertToAudio, state => ({
        ...state,
        status: SliceStatus.pending,
        isGeneratingAudio: true,
      }))
      .addCase(convertToAudioSuccess, (state, action) => ({
        ...state,
        audioGenerationJobId: action.payload.jobId,
        status: SliceStatus.resolved,
      }))
      .addCase(convertToAudioFailure, state => ({
        ...state,
        isGeneratingAudio: false,
        status: SliceStatus.rejected,
      }))
      .addCase(regenerateAudioParagraph, state => ({
        ...state,
        isRegeneratingParagraph: true,
        status: SliceStatus.pending,
        error: '',
      }))
      .addCase(regenerateAudioParagraphSuccess, (state, action) => ({
        ...state,
        paragraphRegenerationJobId: action.payload.jobId,
        status: SliceStatus.resolved,
        error: '',
      }))
      .addCase(generateAudioCoverImage, state => ({
        ...state,
        status: SliceStatus.pending,
        isGeneratingImage: true,
        error: '',
      }))
      .addCase(generateAudioCoverImageSuccess, (state, action) => ({
        ...state,
        coverImageUrl: action.payload.coverImageUrl,
        status: SliceStatus.resolved,
        isGeneratingImage: false,
        error: '',
      }))
      .addCase(generateAudioCoverImageFailure, state => ({
        ...state,
        status: SliceStatus.rejected,
        isGeneratingImage: false,
        error: '',
      }))
      .addCase(getJobStatus, state => ({
        ...state,
        status: SliceStatus.pending,
        error: '',
      }))
      .addCase(getJobStatusSuccess, (state, {payload}) => {
        if (payload.type === EndPoints.SpeechGenerationStatus) {
          return {
            ...state,
            audioGenerationJobStatus: payload.state,
            isGeneratingAudio: payload.state === 'processing',
            generatedAudio:
              (payload.result?.message as ConvertToAudioResult)?.audio ??
              state.generatedAudio,
            generatedScript:
              (payload.result?.message as ConvertToAudioResult)?.script ??
              state.generatedScript,
            status: SliceStatus.resolved,
          };
        } else if (payload.type === EndPoints.RegenerateTrackStatus) {
          return {
            ...state,
            audioRegenerationJobStatus: payload.state,
            isRegeneratingAudio: payload.state === 'processing',
            coverImageUrl:
              (payload.result?.message as RegenerateAudioTrackResult)
                ?.coverImageUrl ?? state.coverImageUrl,
            generatedAudio:
              (payload.result?.message as RegenerateAudioTrackResult)?.audio ??
              state.generatedAudio,
            generatedScript:
              (payload.result?.message as RegenerateAudioTrackResult)?.script ??
              state.generatedScript,
            status: SliceStatus.resolved,
          };
        } else if (payload.type === EndPoints.RegenerateParagraphStatus) {
          return {
            ...state,
            paragraphRegenerationJobStatus: payload.state,
            isRegeneratingParagraph: payload.state === 'processing',
            generatedAudio:
              (payload.result?.message as RegenerateAudioParagraphResult)
                ?.updatedAudio ?? state.generatedAudio,
            generatedScript:
              (payload.result?.message as RegenerateAudioParagraphResult)
                ?.updatedScript ?? state.generatedScript,
            status: SliceStatus.resolved,
          };
        }
        return state;
      })
      .addCase(regenerateCoverImage, state => ({
        ...state,
        status: SliceStatus.pending,
        isGeneratingImage: true,
        error: '',
      }))
      .addCase(regenerateCoverImageSuccess, (state, action) => ({
        ...state,
        status: SliceStatus.resolved,
        coverImageUrl: action.payload.coverImageUrl,
        isGeneratingImage: false,
        error: '',
      }))
      .addCase(regenerateCoverImageFailure, state => ({
        ...state,
        status: SliceStatus.rejected,
        isGeneratingImage: false,
        error: '',
      }))
      .addCase(generateAudioSuggestions, state => ({
        ...state,
        status: SliceStatus.pending,
        isGeneratingImage: true,
        error: '',
      }))
      .addCase(generateAudioSuggestionsSuccess, state => ({
        ...state,
        status: SliceStatus.resolved,
        error: '',
      }))
      .addCase(submitAudio, state => ({
        ...state,
        status: SliceStatus.pending,
        error: '',
      }))
      .addCase(submitAudioSuccess, state => ({
        ...state,
        status: SliceStatus.resolved,
        error: '',
      }))
      .addCase(regenerateAudioTrack, state => ({
        ...state,
        isRegeneratingAudio: true,
        status: SliceStatus.pending,
        error: '',
      }))
      .addCase(regenerateAudioTrackFailure, state => ({
        ...state,
        isRegeneratingAudio: false,
        status: SliceStatus.rejected,
        error: '',
      }))
      .addCase(regenerateAudioTrackSuccess, (state, action) => ({
        ...state,
        audioRegenerationJobId: action.payload.jobId,
        status: SliceStatus.resolved,
        error: '',
      }))
      .addCase(deleteAudio, state => ({
        ...state,
        status: SliceStatus.pending,
        error: '',
      }))
      .addCase(deleteAudioSuccess, (state, payload) => ({
        ...state,
        status: SliceStatus.resolved,
        audios: {
          audios: state.audios.audios.filter(
            ({_id}) => _id !== payload.payload.audioId,
          ),
          pagination: state.audios.pagination,
        },
        error: '',
      }))
      .addCase(resetWellniteAudios, () => wellniteAudiosSliceInitialState),
});

export const {reducer: wellniteAudiosReducer, name: wellniteAudiosReducerName} =
  wellniteAudiosSlice;

export type TWellniteAudiosActions =
  | ReturnType<typeof cloneVoice>
  | ReturnType<typeof cloneVoiceSuccess>
  | ReturnType<typeof cloneVoiceFailure>
  | ReturnType<typeof generateAudioScript>
  | ReturnType<typeof generateAudioScriptSuccess>
  | ReturnType<typeof generateAudioScriptFailure>
  | ReturnType<typeof convertToAudio>
  | ReturnType<typeof convertToAudioSuccess>
  | ReturnType<typeof convertToAudioFailure>
  | ReturnType<typeof regenerateAudioParagraph>
  | ReturnType<typeof regenerateAudioParagraphSuccess>
  | ReturnType<typeof regenerateAudioParagraphFailure>
  | ReturnType<typeof regenerateCoverImage>
  | ReturnType<typeof regenerateCoverImageSuccess>
  | ReturnType<typeof regenerateCoverImageFailure>
  | ReturnType<typeof generateAudioSuggestions>
  | ReturnType<typeof generateAudioSuggestionsSuccess>
  | ReturnType<typeof generateAudioSuggestionsFailure>
  | ReturnType<typeof submitAudio>
  | ReturnType<typeof submitAudioSuccess>
  | ReturnType<typeof submitAudioFailure>
  | ReturnType<typeof regenerateAudioTrack>
  | ReturnType<typeof regenerateAudioTrackSuccess>
  | ReturnType<typeof regenerateAudioTrackFailure>
  | ReturnType<typeof deleteAudio>
  | ReturnType<typeof deleteAudioSuccess>
  | ReturnType<typeof deleteAudioFailure>
  | ReturnType<typeof generateAudioCoverImage>
  | ReturnType<typeof generateAudioCoverImageSuccess>
  | ReturnType<typeof generateAudioCoverImageFailure>
  | ReturnType<typeof getJobStatus>
  | ReturnType<typeof getJobStatusSuccess>
  | ReturnType<typeof getJobStatusFailure>
  | ReturnType<typeof resetWellniteAudios>
  | ReturnType<typeof getAudios>
  | ReturnType<typeof getAudiosSuccess>
  | ReturnType<typeof getAudiosFailure>;

export const wellniteAudiosActions = {
  cloneVoice,
  cloneVoiceSuccess,
  cloneVoiceFailure,
  getAudios,
  getAudiosSuccess,
  getAudiosFailure,
  generateAudioScript,
  generateAudioScriptSuccess,
  generateAudioScriptFailure,
  convertToAudio,
  convertToAudioSuccess,
  convertToAudioFailure,
  regenerateAudioParagraph,
  regenerateAudioParagraphSuccess,
  regenerateAudioParagraphFailure,
  regenerateCoverImage,
  regenerateCoverImageSuccess,
  regenerateCoverImageFailure,
  generateAudioSuggestions,
  generateAudioSuggestionsSuccess,
  generateAudioSuggestionsFailure,
  submitAudio,
  submitAudioSuccess,
  submitAudioFailure,
  regenerateAudioTrack,
  regenerateAudioTrackSuccess,
  regenerateAudioTrackFailure,
  deleteAudio,
  deleteAudioSuccess,
  deleteAudioFailure,
  generateAudioCoverImage,
  generateAudioCoverImageSuccess,
  generateAudioCoverImageFailure,
  getJobStatus,
  getJobStatusSuccess,
  getJobStatusFailure,
  resetWellniteAudios,
};

export type WellniteAudiosState = ReturnType<typeof wellniteAudiosReducer>;
